import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { showNotification } from "../../../../shared/Notification/Notification";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SocketContext } from "../../../../services/Socket/Socket.context";
import { CouponCodesContext } from "../../../../services/CouponCodes/CouponCodes.context";
import { GiTicket } from "react-icons/gi";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";

const applicableOn = [
  { label: "All", value: "all" },
  { label: "Services", value: "services" },
  { label: "Products", value: "products" },
  { label: "Bike Service", value: "bikeService" },
  { label: "Car Service", value: "carService" },
];

const errors = {
  codeRequired: "Coupon Code Required",
  descriptionRequired: "Description required",
  discountRequired: "Discount required (in percentage) must be greater than 0 ",
  validFromRequired: "Please select coupon valid from date",
  validUntilRequired: "Please select coupon valid until date",
  validMismatch: "Valid until should be greater than valid from",
  applicableOnRequired: "Select applicable on types",
  amountRequired: "Please enter amount in discount must be greater than 0",
  usageLimitRequired:
    "Please enter how many times coupon code can be used and minimum should be one",
  typeRequired: "Please select discount type either percentage or amount",
};

const commonInputFieldProps = {
  value: "",
  focused: false,
  error: false,
  errorMessage: "",
};

const defaultInputState = {
  code: {
    ...commonInputFieldProps,
  },
  description: {
    ...commonInputFieldProps,
  },
  discount: {
    ...commonInputFieldProps,
  },
  validFrom: {
    ...commonInputFieldProps,
    value: moment(),
  },
  validUntil: {
    ...commonInputFieldProps,
    value: moment(),
  },
  validForFirstPayment: {
    ...commonInputFieldProps,
    value: false,
  },
  applicableOn: {
    ...commonInputFieldProps,
    value: applicableOn.find((a) => a.value === "services"),
  },
  amount: {
    ...commonInputFieldProps,
  },
  usageLimit: {
    ...commonInputFieldProps,
  },
  type: {
    ...commonInputFieldProps,
  },

  minimumPurchaseAmount: {
    ...commonInputFieldProps,
    value: 0,
  },

  visible: {
    ...commonInputFieldProps,
    value: true,
  },
  id: {
    ...commonInputFieldProps,
  },
};

const CreateEditCouponCode = ({ mode }) => {
  const [couponCode, SetCouponCode] = useState(null);
  const [inputs, setInputs] = useState(defaultInputState);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { onCreateCouponCode, onGetCouponCode, onUpdateCouponCode } =
    useContext(CouponCodesContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (mode) {
      let title = mode === "edit" ? "Edit Coupon Code" : "Add Coupon Code";
      document.title = title;
    }
    if (mode === "edit") {
      let editId = searchParams.get("id");
      if (!editId) {
        navigate("/dashboard/coupon-codes");
        return;
      }
      if (editId) {
        onGetCouponCode(
          editId,
          (result) => {
            let couponCodeData = result.couponCode;
            SetCouponCode(couponCodeData);
            if (couponCodeData) {
              let {
                code,
                description,
                type,
                discount,
                amount,
                validFrom,
                validUntil,
                usageLimit,
                minimumPurchaseAmount,
                applicableOn: applicable,
                visible,
                _id,
                validForFirstPayment,
              } = couponCodeData;
              setInputs((prevState) => ({
                ...prevState,
                code: {
                  ...commonInputFieldProps,
                  value: code,
                },
                description: {
                  ...commonInputFieldProps,
                  value: description,
                },
                type: {
                  ...commonInputFieldProps,
                  value: type,
                },
                discount: {
                  ...commonInputFieldProps,
                  value: discount,
                },
                amount: {
                  ...commonInputFieldProps,
                  value: amount,
                },
                validFrom: {
                  ...commonInputFieldProps,
                  value: moment(validFrom),
                },
                validUntil: {
                  ...commonInputFieldProps,
                  value: moment(validUntil),
                },
                usageLimit: {
                  ...commonInputFieldProps,
                  value: usageLimit || "",
                },
                minimumPurchaseAmount: {
                  ...commonInputFieldProps,
                  value: minimumPurchaseAmount,
                },
                applicableOn: {
                  ...commonInputFieldProps,
                  value: applicableOn.find((a) => a.value === applicable),
                },

                visible: {
                  ...commonInputFieldProps,
                  value: visible,
                },
                validForFirstPayment: {
                  ...commonInputFieldProps,
                  value: validForFirstPayment,
                },
                id: {
                  value: _id,
                },
              }));
            } else {
              navigate("/dashboard/coupon-codes");
            }
          },
          () => {
            navigate("/dashboard/coupon-codes");
          },
          false,
          false
        );
      }
    }
  }, [mode]);

  const onValueChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        error: false,
        errorMessage: "",
        value,
      },
    }));
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    let hadErrors = false;
    const setErrorMessage = (name, message) => {
      setInputs((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          error: true,
          errorMessage: message,
        },
      }));
      hadErrors = true;
    };
    const returnValue = (value) => {
      return typeof value === "string" ? value?.trim() : value;
    };
    let {
      code,
      description,
      type,
      discount,
      amount,
      validFrom,
      validUntil,
      usageLimit,
      minimumPurchaseAmount,
      applicableOn,
      visible,
      validForFirstPayment,
    } = inputs;
    code = returnValue(code.value);
    description = returnValue(description.value);
    type = returnValue(type.value);
    discount = returnValue(discount.value);
    amount = returnValue(amount.value);
    validFrom = returnValue(validFrom.value);
    validUntil = returnValue(validUntil.value);

    usageLimit = returnValue(usageLimit.value);
    minimumPurchaseAmount = returnValue(minimumPurchaseAmount.value);
    applicableOn = returnValue(applicableOn.value);
    visible = returnValue(visible.value);
    validForFirstPayment = returnValue(validForFirstPayment.value);

    if (!code) {
      setErrorMessage("code", errors.codeRequired);
    }
    if (!description) {
      setErrorMessage("description", errors.descriptionRequired);
    }
    if (!type) {
      setErrorMessage("type", errors.typeRequired);
      return;
    }
    let numRegEx = /^[1-9]\d*$/;
    if (type === "discount" && !numRegEx.test(discount)) {
      setErrorMessage("discount", errors.discountRequired);
    }
    if (type === "amount" && !numRegEx.test(amount)) {
      setErrorMessage("amount", errors.amountRequired);
    }
    if (!validFrom) {
      setErrorMessage("validFrom", errors.validFromRequired);
    }
    if (!validUntil) {
      setErrorMessage("validUntil", errors.validUntilRequired);
    }
    let validMismatch = validUntil.isBefore(validFrom);
    if (validMismatch) {
      setErrorMessage("validUntil", errors.validMismatch);
    }

    if (usageLimit) {
      if (!numRegEx.test(usageLimit)) {
        setErrorMessage("usageLimit", errors.usageLimitRequired);
      }
    }

    let minAmntRegEx = /^[0-9]\d*$/;
    if (!minAmntRegEx.test(minimumPurchaseAmount)) {
      setErrorMessage("minimumPurchaseAmount", "Minimum amount should be 0");
    }
    if (!applicableOn) {
      setErrorMessage("applicableOn", errors.applicableOnRequired);
    }

    if (hadErrors) {
      return;
    }
    validFrom = validFrom.valueOf();
    validUntil = validUntil.valueOf();
    let data = {
      code: code,
      description: description,
      type: type,
      discount: type === "amount" ? 0 : Number(discount),
      amount: type === "discount" ? 0 : Number(amount),
      validFrom: validFrom,
      validUntil: validUntil,
      minimumPurchaseAmount: Number(minimumPurchaseAmount),
      applicableOn: applicableOn.value,
      visible: visible,
      validForFirstPayment: validForFirstPayment,
    };
    if (usageLimit) {
      data.usageLimit = usageLimit;
    }

    if (mode === "edit") {
      onUpdateCouponCode(inputs.id.value, data, () => {
        navigate("/dashboard/coupon-codes");
      });
    } else {
      onCreateCouponCode(data, () => {
        navigate("/dashboard/coupon-codes");
      });
    }
  };

  return (
    <section>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {mode === "create" ? "Add Coupon Code" : "Edit Coupon Code"}
          </Typography>
          <br />
          <Box
            component="form"
            noValidate
            onSubmit={onSubmitForm.bind(this)}
            sx={{ mt: 2 }}
          >
            <Grid container spacing={2}>
              {/* for form */}

              {/* code */}
              <Grid item md={6}>
                <TextField
                  error={inputs.code.error}
                  helperText={inputs.code.errorMessage}
                  margin="normal"
                  placeholder="Enter COUPON CODE "
                  required
                  fullWidth
                  id="code"
                  label="Code"
                  name="code"
                  value={inputs.code.value}
                  inputProps={{
                    style: {
                      textTransform: "uppercase",
                    },
                  }}
                  onChange={onValueChangeHandler}
                />
              </Grid>

              {/* description */}
              <Grid item md={6}>
                <TextField
                  error={inputs.description.error}
                  helperText={inputs.description.errorMessage}
                  margin="normal"
                  placeholder="Description of coupon code"
                  required
                  fullWidth
                  id="description"
                  label="Description"
                  name="description"
                  value={inputs.description.value}
                  onChange={onValueChangeHandler}
                />
              </Grid>

              {/* type */}
              <Grid item md={6}>
                <br />
                <RadioGroup
                  onChange={(e) =>
                    onValueChangeHandler({
                      target: {
                        value: e.target.value,
                        name: "type",
                      },
                    })
                  }
                  value={inputs.type.value || null}
                >
                  <Grid container>
                    <Grid item md={6}>
                      <FormControlLabel
                        value="discount"
                        control={<Radio />}
                        label="Discount (in percentage)"
                        labelPlacement="end"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel
                        value="amount"
                        control={<Radio />}
                        label="Discount (in Amount)"
                        labelPlacement="end"
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
                {inputs.type.error && (
                  <FormHelperText error>
                    {inputs.type.errorMessage}
                  </FormHelperText>
                )}
              </Grid>

              {inputs.type.value && (
                <>
                  {/* discount  */}
                  {inputs.type.value && inputs.type.value === "discount" && (
                    <Grid item md={6}>
                      <TextField
                        type="number"
                        error={inputs.discount.error}
                        helperText={inputs.discount.errorMessage}
                        margin="normal"
                        required
                        fullWidth
                        id="discount"
                        placeholder="Enter how much percent to be discounted?"
                        label="Percentage"
                        name="discount"
                        value={inputs.discount.value}
                        onChange={onValueChangeHandler}
                      />
                    </Grid>
                  )}

                  {/* amount  */}
                  {inputs.type.value && inputs.type.value === "amount" && (
                    <Grid item md={6}>
                      <TextField
                        type="number"
                        error={inputs.amount.error}
                        helperText={inputs.amount.errorMessage}
                        margin="normal"
                        required
                        fullWidth
                        id="amount"
                        label="Amount"
                        placeholder="Enter how much amount to be discounted?"
                        name="amount"
                        value={inputs.amount.value}
                        onChange={onValueChangeHandler}
                      />
                    </Grid>
                  )}

                  {/* valid from  */}
                  <Grid item md={6}>
                    <DateTimePicker
                      label="Valid From"
                      format="DD-MM-YYYY hh:mm:ss A"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: inputs.validFrom.error,
                          helperText: inputs.validFrom.errorMessage,
                        },
                      }}
                      value={inputs.validFrom.value}
                      onChange={(newValue) => {
                        onValueChangeHandler({
                          target: {
                            name: "validFrom",
                            value: newValue,
                          },
                        });
                      }}
                    />
                  </Grid>

                  {/* valid until */}
                  <Grid item md={6}>
                    <DateTimePicker
                      label="Valid Until"
                      format="DD-MM-YYYY hh:mm:ss A"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: inputs.validUntil.error,
                          helperText: inputs.validUntil.errorMessage,
                        },
                      }}
                      value={inputs.validUntil.value}
                      onChange={(newValue) => {
                        onValueChangeHandler({
                          target: {
                            name: "validUntil",
                            value: newValue,
                          },
                        });
                      }}
                    />
                  </Grid>

                  {/* usage limit */}
                  <Grid item md={4}>
                    <TextField
                      error={inputs.usageLimit.error}
                      helperText={inputs.usageLimit.errorMessage}
                      margin="normal"
                      placeholder="how many times coupon code can be applied for one user"
                      type="number"
                      fullWidth
                      id="usageLimit"
                      label="Usage Limit"
                      name="usageLimit"
                      value={inputs.usageLimit.value}
                      onChange={onValueChangeHandler}
                    />
                  </Grid>

                  {/* minimum purchase amount */}
                  <Grid item md={4}>
                    <TextField
                      error={inputs.minimumPurchaseAmount.error}
                      helperText={inputs.minimumPurchaseAmount.errorMessage}
                      margin="normal"
                      placeholder="If any minimum purchasee amount applicable"
                      required
                      type="number"
                      fullWidth
                      id="minimumPurchaseAmount"
                      label="Minimum Purchase Amount"
                      name="minimumPurchaseAmount"
                      value={inputs.minimumPurchaseAmount.value}
                      onChange={onValueChangeHandler}
                    />
                  </Grid>

                  {/* applicable on  */}
                  <Grid item md={4}>
                    <Autocomplete
                      className="mt-1 w-100"
                      disablePortal
                      value={inputs.applicableOn.value || null}
                      id="applicableOn"
                      options={applicableOn}
                      onChange={(e, newValue) => {
                        onValueChangeHandler({
                          target: {
                            value: newValue,
                            name: "applicableOn",
                          },
                        });
                      }}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Applicable On" />
                      )}
                    />
                  </Grid>

                  {/* visible */}
                  <Grid item md={4}>
                    <FormControlLabel
                      onChange={(e) =>
                        onValueChangeHandler({
                          target: {
                            name: "visible",
                            value: e.target.checked,
                          },
                        })
                      }
                      control={<Checkbox checked={inputs.visible.value} />}
                      label="Visible"
                    />
                  </Grid>

                  {/* first paymet */}
                  <Grid item md={4}>
                    <FormControlLabel
                      onChange={(e) =>
                        onValueChangeHandler({
                          target: {
                            name: "validForFirstPayment",
                            value: e.target.checked,
                          },
                        })
                      }
                      control={
                        <Checkbox checked={inputs.validForFirstPayment.value} />
                      }
                      label="Only valid on first payment"
                    />
                  </Grid>
                </>
              )}

              {/* submit button */}

              <LoadingButton
                type="submit"
                fullWidth
                loadingPosition="end"
                endIcon={<GiTicket />}
                color="primary"
                loading={loading}
                loadingIndicator={"Adding..."}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {!loading && mode === "edit"
                  ? "PROCEED & UPDATE"
                  : "PROCEED & ADD"}
              </LoadingButton>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </section>
  );
};

export default CreateEditCouponCode;
