import { useContext, useEffect, useState } from "react";
import { CategoriesAndProductsContext } from "../../../../services/CategoriesAndProducts/CategoriesAndProducts.context";
import {
  NotFoundContainer,
  NotFoundContainerImage,
  NotFoundText,
} from "../../../../styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { FaPlus } from "react-icons/fa";
import Swal from "sweetalert2";
import { SocketContext } from "../../../../services/Socket/Socket.context";
import { FiEdit, FiPlusCircle, FiTrash } from "react-icons/fi";
import NoDataImg from "../../../../assets/no_data.png";
const defaultInputProps = {
  value: "",
  error: false,
  errorMessage: "",
};

const defaultInputs = {
  name: {
    ...defaultInputProps,
  },
};

const errors = {
  nameRequired: "Category Name Required",
};

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const {
    onGetCategories,
    onDeleteCategory,
    onUpdateCategory,
    onCreateCategory,
  } = useContext(CategoriesAndProductsContext);
  const { socket, onFetchEvent, onEmitEvent } = useContext(SocketContext);
  const [showModal, setShowModal] = useState(false);
  const [parentId, setParentId] = useState(null);
  const [category, setCategory] = useState(null);
  const [inputs, setInputs] = useState(defaultInputs);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    onGetCategories(
      (result) => {
        console.log(result);
        setCategories(result.categories);
      },
      true,
      false
    );
  };

  const handleDelete = async (categoryId) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You are about to delete this category. This action cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });

      if (result.isConfirmed) {
        onDeleteCategory(categoryId, () => {
          onEmitEvent("refreshCategories");
        });
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const onClickEdit = async (category) => {
    setCategory(category);
    setInputs((p) => ({
      ...p,
      name: {
        error: false,
        errorMessage: "",
        value: category.name,
      },
    }));
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setParentId(null);
    setCategory(null);
    setInputs(defaultInputs);
  };

  const onAddNewCategory = (parentId) => {
    setParentId(parentId);
    setShowModal(true);
  };

  const onValueChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        error: false,
        errorMessage: "",
        value,
      },
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let hadErrors = false;
    const setErrorMessage = (name, message) => {
      setInputs((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          error: true,
          errorMessage: message,
        },
      }));
      hadErrors = true;
    };
    let { name } = inputs;
    name = name.value.trim();
    if (!name) {
      setErrorMessage("name", errors.nameRequired);
    }

    if (hadErrors) {
      return;
    }

    if (category) {
      let data = {
        name: name.trim(),
      };
      onUpdateCategory(category._id, data, () => {
        onEmitEvent("refreshCategories");
        handleClose();
      });
    } else {
      let data = {
        name: name.trim(),
        parentId: parentId,
      };
      onCreateCategory(data, () => {
        onEmitEvent("refreshCategories");
        handleClose();
      });
    }
  };

  useEffect(() => {
    if (socket) {
      const eventHandler = (data) => {
        getCategories();
      };
      onFetchEvent("refreshCategories", eventHandler);
      return () => {
        socket?.off("refreshCategories", eventHandler);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onFetchEvent, socket]);

  const ListItem = ({ children }) => {
    return (
      <>
        {children.map((child, i) => {
          let { _id, name, children } = child;
          return (
            <div key={_id}>
              <AccordionSummary>
                <Typography
                  sx={{
                    width: "33%",
                    fontSize: 16,
                    fontWeight: children.length > 0 ? "bold" : "500",
                    flexShrink: 0,
                  }}
                >
                  {name}
                </Typography>
                <div>
                  <FiPlusCircle
                    size={20}
                    onClick={() => onAddNewCategory(_id)}
                    color="green"
                    className="ml-2 cursor-pointer"
                  />
                  <FiEdit
                    size={20}
                    onClick={() => onClickEdit(child)}
                    color="orange"
                    className="ml-2 cursor-pointer"
                  />
                  <FiTrash
                    size={20}
                    onClick={() => handleDelete(_id)}
                    color="tomato"
                    className="ml-2 cursor-pointer"
                  />
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {children.length > 0 && <ListItem children={children} />}
              </AccordionDetails>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <section>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          startIcon={<FaPlus />}
          onClick={() => setShowModal(true)}
        >
          Add Category
        </Button>
      </Box>

      {categories.length > 0 && (
        <>
          {categories.map((category, i) => {
            let { name, _id, children } = category;
            return (
              <Accordion expanded={true} key={_id}>
                <AccordionSummary>
                  <Typography
                    sx={{
                      width: "33%",
                      fontSize: 20,
                      fontWeight: "bold",
                      flexShrink: 0,
                    }}
                  >
                    {name}
                  </Typography>
                  <div>
                    <FiPlusCircle
                      size={20}
                      onClick={() => onAddNewCategory(_id)}
                      color="green"
                      className="ml-2 cursor-pointer"
                    />
                    <FiEdit
                      size={20}
                      onClick={() => onClickEdit(category)}
                      color="orange"
                      className="ml-2 cursor-pointer"
                    />
                    <FiTrash
                      size={20}
                      onClick={() => handleDelete(_id)}
                      color="tomato"
                      className="ml-2 cursor-pointer"
                    />
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {children.length > 0 && <ListItem children={children} />}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </>
      )}

      {categories.length === 0 && (
        <NotFoundContainer>
          <div>
            <NotFoundText>No Categories Found</NotFoundText>
            <NotFoundContainerImage src={NoDataImg} alt="..." />
          </div>
        </NotFoundContainer>
      )}

      <Dialog open={showModal} onClose={handleClose} fullWidth>
        <DialogTitle>{category ? "Edit Category" : "Add Category"}</DialogTitle>
        <DialogContent>
          <Box component="form" noValidate onSubmit={onSubmit.bind(this)}>
            <Grid container columnSpacing={1}>
              {/* name */}
              <Grid item md={12}>
                <TextField
                  error={inputs.name.error}
                  helperText={inputs.name.errorMessage}
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="Category Name"
                  name="name"
                  value={inputs.name.value}
                  onChange={onValueChangeHandler}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onSubmit} variant="contained">
            {" "}
            {category ? "PROCEED & UPDATE" : "PROCEED & ADD"}
          </Button>
          <Button color="inherit" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );
};

export default Categories;
