// MenuItemList.jsx
import React, { createElement } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FaPenAlt } from "react-icons/fa";

const MenuItemList = ({
  item,
  optionsEl,
  handleMenuClose,
  onEdit,
  onDelete,
  menuItems,
}) => {
  return (
    <Menu
      anchorEl={optionsEl}
      open={Boolean(optionsEl)}
      onClose={handleMenuClose}
    >
      {menuItems.map((menuItem, index) => {
        let { label, onClick, icon } = menuItem;
        return (
          <MenuItem onClick={onClick} key={index}>
            {icon && React.createElement(icon)}
            <span className="ml-1">{label}</span>
          </MenuItem>
        );
      })}
    </Menu>
  );
};

export default MenuItemList;
