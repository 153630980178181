/* eslint-disable react/jsx-no-duplicate-props */
import { useContext, useEffect, useState } from "react";
import { ServiceCentersContext } from "../../../../services/ServiceCenters/ServiceCenters.context";
import {
  NotFoundContainer,
  NotFoundContainerImage,
  NotFoundText,
} from "../../../../styles";
import NoDataImg from "../../../../assets/no_data.png";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import {
  FaDirections,
  FaPencilAlt,
  FaPhoneAlt,
  FaPlus,
  FaTrashAlt,
} from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import classes from "./ServiceCenters.module.css";
import CreateEditServiceCenter from "./CreateEditServiceCenter";
import { SocketContext } from "../../../../services/Socket/Socket.context";
import Swal from "sweetalert2";
import MenuItemList from "../../../../utility/MenuItemList";

const ServiceCenters = ({ title }) => {
  const { onGetServiceCenters, onDeleteServiceCenter } = useContext(
    ServiceCentersContext
  );
  const { socket, onFetchEvent, onEmitEvent } = useContext(SocketContext);
  const [serviceCenters, setServiceCenters] = useState([]);
  const [orgServiceCenters, setOrgServiceCenters] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const location = useLocation();
  const [mode, setMode] = useState(null);
  const navigate = useNavigate();
  const [optionsEl, setOptionsEl] = useState(null);
  const [selectedServiceCenter, setSelectedServiceCenter] = useState(null);

  const handleMenuClick = (event, item) => {
    setSelectedServiceCenter(item);
    setOptionsEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setOptionsEl(null);
  };

  useEffect(() => {
    document.title = title;
    getServiceCenters();
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get("mode");
    if (mode) {
      setMode(mode);
    }
  }, [location.search]);

  useEffect(() => {
    if (socket) {
      const eventHandler = (data) => {
        getServiceCenters();
      };
      onFetchEvent("refreshServiceCenters", eventHandler);
      return () => {
        socket?.off("refreshServiceCenters", eventHandler);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onFetchEvent, socket]);

  const getServiceCenters = () => {
    onGetServiceCenters(
      (result) => {
        if (result && result.serviceCenters) {
          setServiceCenters(result.serviceCenters);
          setOrgServiceCenters(result.serviceCenters);
        }
      },
      false,
      false
    );
  };

  const onGetDirections = (location) => {
    let { latitude, longitude } = location;
    const mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(mapUrl, "_blank");
  };

  const onCall = (phoneNumber) => {
    let url = `tel:${phoneNumber}`;
    window.open(url, "_blank");
  };

  const onChangeSearchKeyword = (e) => {
    let value = e.target.value;
    setSearchKeyword(value);
    let filtered = orgServiceCenters;
    if (value) {
      value = value.toLowerCase();
      let finalServiceCenters = _.cloneDeep(orgServiceCenters);
      filtered = finalServiceCenters.filter((serviceCenter) => {
        let { name, description, phone, address } = serviceCenter;
        let nameFound = name.toLowerCase().includes(value);
        let descFound = description
          ? description.toLowerCase().includes(value)
          : false;
        let phoneFound = phone.toString().includes(value);
        let addressFound = address.toLowerCase().includes(value);
        return nameFound || descFound || phoneFound || addressFound;
      });
    }
    setServiceCenters(filtered);
  };

  const onClickEditServiceCenter = (serviceCenter) => {
    handleMenuClose();
    navigate("?mode=edit&id=" + serviceCenter._id);
  };

  const onClickDeleteServiceCenter = (serviceCenter) => {
    handleMenuClose();
    Swal.fire({
      title: "Are you sure to delete?",
      text: `${serviceCenter.name}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteServiceCenter(serviceCenter._id, (result) => {
          onEmitEvent("refreshServiceCenters");
        });
      }
    });
  };

  return (
    <section>
      {!mode && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Link to={"?mode=create"}>
              <Button variant="contained" startIcon={<FaPlus />}>
                Add Service Center
              </Button>
            </Link>
          </Box>

          {orgServiceCenters && orgServiceCenters.length > 0 && (
            <TextField
              margin="normal"
              fullWidth
              id="search"
              variant="standard"
              label="Search By Keyword"
              name="search"
              value={searchKeyword}
              onChange={onChangeSearchKeyword}
            />
          )}

          {serviceCenters.length === 0 && (
            <NotFoundContainer>
              <div>
                <NotFoundText>No Service Centers Found</NotFoundText>
                <NotFoundContainerImage src={NoDataImg} alt="..." />
              </div>
            </NotFoundContainer>
          )}
          {serviceCenters && serviceCenters.length > 0 && (
            <Grid container className="mt-1" spacing={3}>
              {serviceCenters.map((serviceCenter, index) => {
                let {
                  name,
                  description,
                  image,
                  countryCode,
                  phone,
                  address,
                  location,
                } = serviceCenter;
                let phoneNumber =
                  "+" + countryCode.toString() + phone.toString();
                return (
                  <Grid key={index} item md={4}>
                    <Card sx={{ marginBottom: 5 }}>
                      <CardHeader
                        avatar={
                          <Avatar sx={{ bgcolor: red[500] }}>
                            {name.substring(0, 1)}
                          </Avatar>
                        }
                        action={
                          <>
                            <IconButton
                              aria-controls={`menu-${index}`}
                              aria-haspopup="true"
                              onClick={(event) =>
                                handleMenuClick(event, serviceCenter)
                              }
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </>
                        }
                        title={name}
                        subheader={description}
                      />
                      <CardMedia
                        component="img"
                        height="194"
                        image={image}
                        alt={"..."}
                      />
                      <CardContent>
                        <Typography variant="body2" color="text.secondary">
                          {address}
                        </Typography>
                      </CardContent>
                      <CardActions
                        disableSpacing
                        sx={{
                          justifyContent: "end",
                        }}
                      >
                        <IconButton
                          aria-label="call"
                          onClick={() => onCall(phoneNumber)}
                        >
                          <FaPhoneAlt />
                        </IconButton>
                        <IconButton
                          aria-label="directions"
                          onClick={() => onGetDirections(location)}
                        >
                          <FaDirections />
                        </IconButton>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          )}
          {selectedServiceCenter && (
            <MenuItemList
              item={selectedServiceCenter}
              optionsEl={optionsEl}
              handleMenuClose={handleMenuClose}
              menuItems={[
                {
                  icon: FaPencilAlt,
                  onClick: () =>
                    onClickEditServiceCenter(selectedServiceCenter),
                  label: "Edit",
                },
                {
                  icon: FaTrashAlt,
                  onClick: () =>
                    onClickDeleteServiceCenter(selectedServiceCenter),
                  label: "Delete",
                },
              ]}
            />
          )}
        </>
      )}
      {mode && <CreateEditServiceCenter mode={mode} />}
    </section>
  );
};

export default ServiceCenters;
