/* eslint-disable react-hooks/exhaustive-deps */
import { createContext } from "react";

import useHttp from "../../hooks/useHttp";

export const PaymentsContext = createContext({
  onGetPayments: (callback, loader, notify) => null,
});

export const PaymentsContextProvider = ({ children }) => {
  const { sendRequest } = useHttp();

  const baseUrl = "/";

  const onGetPayments = async (
    callback = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + "payment",
      },
      {
        successCallback: callback,
      },
      loader,
      notify
    );
  };

  return (
    <PaymentsContext.Provider
      value={{
        onGetPayments,
      }}
    >
      {children}
    </PaymentsContext.Provider>
  );
};
