/* eslint-disable react/jsx-no-duplicate-props */
import { useContext, useEffect, useState } from "react";
import {
  NotFoundContainer,
  NotFoundContainerImage,
  NotFoundText,
} from "../../../../styles";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import ViewIcon from "@mui/icons-material/RemoveRedEye";
import { FaMotorcycle } from "react-icons/fa";
import { AiFillCar } from "react-icons/ai";
import { MdFilterAltOff } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { SocketContext } from "../../../../services/Socket/Socket.context";
import Swal from "sweetalert2";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import DeleteIcon from "@mui/icons-material/Delete";
import { ServicesContext } from "../../../../services/Services/Services.context";
import classes from "./Services.module.css";
import { getServiceStatusColor } from "../../../../utility/helper";
import { ServiceCentersContext } from "../../../../services/ServiceCenters/ServiceCenters.context";
import moment from "moment";
import CreateEditService from "./CreateEditService";
import NoDataImg from "../../../../assets/no_data.png";

const filtersInputState = {
  serviceCenter: {
    value: null,
  },
  status: {
    value: null,
  },
};

const statuses = [
  { label: "SCHEDULED", value: "scheduled" },
  { label: "WAITING FOR APPROVAL", value: "waiting" },
  { label: "PROGRESS", value: "progress" },
  { label: "COMPLETED", value: "completed" },
  { label: "WAITING FOR DELIVERY", value: "payment" },
  { label: "CANCELLED", value: "cancelled" },
  { label: "DELIVERED", value: "delivered" },
];

const Services = ({ title }) => {
  const { onGetServices, onDeleteService } = useContext(ServicesContext);
  const { onGetServiceCenters } = useContext(ServiceCentersContext);
  const { socket, onFetchEvent, onEmitEvent } = useContext(SocketContext);
  const [services, setServices] = useState([]);
  const [serviceCenters, setServiceCenters] = useState([]);
  const [orgServices, setOrgServices] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const location = useLocation();
  const [mode, setMode] = useState(null);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [filters, setFilters] = useState(filtersInputState);

  useEffect(() => {
    document.title = title;
  }, []);

  useEffect(() => {
    if (filters) {
      onFilter();
    }
  }, [filters]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get("mode");
    if (mode) {
      setMode(mode);
    } else {
      getServices();
      getServiceCenters();
    }
  }, [location.search]);

  useEffect(() => {
    if (socket) {
      const eventHandler = (data) => {
        getServices();
      };
      onFetchEvent("refreshServices", eventHandler);
      return () => {
        socket?.off("refreshServices", eventHandler);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onFetchEvent, socket]);

  const getServices = () => {
    onGetServices(
      (result) => {
        if (result && result.services) {
          setServices(result.services);
          setOrgServices(result.services);
        }
      },
      true,
      false
    );
  };

  const getServiceCenters = () => {
    onGetServiceCenters(
      (result) => {
        if (result && result.serviceCenters) {
          setServiceCenters(result.serviceCenters);
        }
      },
      false,
      false
    );
  };

  const onChangeSearchKeyword = (e) => {
    let value = e.target.value;
    setSearchKeyword(value);
    let filtered = orgServices;
    if (value) {
      value = value.toLowerCase();
      let finalServices = _.cloneDeep(orgServices);
      filtered = finalServices.filter((service) => {
        let { ticket, user, type } = service;
        let ticketFound = ticket?.toLowerCase().includes(value);
        let typeFound = type?.toLowerCase().includes(value);
        let userFound = user.phone?.toString().toLowerCase().includes(value);

        return ticketFound || userFound || typeFound;
      });
    }
    setServices(filtered);
  };

  const onClickEditService = (service) => {
    navigate("?mode=edit&id=" + service._id);
  };

  const onClickDeleteService = (service) => {
    Swal.fire({
      title: "Are you sure to delete?",
      text: `#${service.ticket}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteService(service._id, (result) => {
          onEmitEvent("refreshServices");
        });
      }
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [sort, setSort] = useState({
    type: "desc",
    field: null,
  });

  const onChangeSorting = (fieldToSort) => {
    var currentServices = services;
    let type = sort.type === "asc" ? "desc" : "asc";
    let fields = ["ticket"];
    if (fields.includes(fieldToSort)) {
      let sortedServices = _.orderBy(currentServices, fieldToSort, type);
      setSort((p) => ({
        ...p,
        type: type,
        field: fieldToSort,
      }));
      setServices(sortedServices);
    }
  };

  const onChangeFilter = (key, value) => {
    setFilters((p) => ({
      ...p,
      [key]: {
        ...p[key],
        value: value,
      },
    }));
  };

  const onClearFilters = () => {
    setFilters(filtersInputState);
    setServices(orgServices);
  };

  const onFilter = () => {
    if (filters) {
      let finalServices = orgServices;
      let serviceCenterId = filters.serviceCenter.value?._id;
      let serviceStatus = filters.status.value?.value;
      if (serviceCenterId && serviceStatus) {
        finalServices = finalServices.filter(
          (s) =>
            s.serviceCenter._id === serviceCenterId &&
            s.status === serviceStatus
        );
      } else if (serviceCenterId) {
        finalServices = finalServices.filter(
          (s) => s.serviceCenter._id === serviceCenterId
        );
      } else if (serviceStatus) {
        finalServices = finalServices.filter((s) => s.status === serviceStatus);
      }
      setServices(finalServices);
    }
  };

  return (
    <section>
      {!mode && (
        <>
          {orgServices && orgServices.length > 0 && (
            <TextField
              margin="normal"
              fullWidth
              id="search"
              variant="standard"
              label="Search By Keyword"
              name="search"
              value={searchKeyword}
              onChange={onChangeSearchKeyword}
            />
          )}

          <Box>
            <Grid container spacing={2} justifyContent={"flex-end"}>
              <Grid item md={3}>
                <Autocomplete
                  disablePortal
                  id="status"
                  className="mt-1"
                  options={statuses}
                  fullWidth
                  value={filters.status.value || null}
                  onChange={(e, newValue) => {
                    onChangeFilter("status", newValue);
                  }}
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      {...props}
                      key={option.label}
                      style={{
                        color: getServiceStatusColor(option.value),
                      }}
                    >
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Filter By Status" />
                  )}
                />
              </Grid>
              <Grid item md={3}>
                <Autocomplete
                  disablePortal
                  id="serviceCenter"
                  className="mt-1"
                  options={serviceCenters}
                  fullWidth
                  value={filters.serviceCenter.value || null}
                  onChange={(e, newValue) => {
                    onChangeFilter("serviceCenter", newValue);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField {...params} label="Filter By Service Center" />
                  )}
                />
              </Grid>{" "}
              <Grid item md={1} className="mt-1-5">
                <IconButton onClick={onClearFilters}>
                  <MdFilterAltOff color="var(--primary)" size={28} />
                </IconButton>
              </Grid>
            </Grid>
          </Box>

          {services.length === 0 && (
            <NotFoundContainer>
              <div>
                <NotFoundText>No Services Found</NotFoundText>
                <NotFoundContainerImage src={NoDataImg} alt="..." />
              </div>
            </NotFoundContainer>
          )}

          {services.length > 0 && (
            <TableContainer component={Paper} sx={{ mt: 4 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>S.NO</TableCell>
                    <TableCell>
                      {" "}
                      <TableSortLabel
                        direction={
                          sort.type && sort.type === "desc" ? "asc" : "desc"
                        }
                        active
                        onClick={() => onChangeSorting("ticket")}
                      >
                        Ticket
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Service Type</TableCell>
                    <TableCell>Vehicle Type </TableCell>
                    <TableCell>Service Center</TableCell>
                    <TableCell>User</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? services.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : services
                  ).map((service, index) => {
                    let {
                      ticket,
                      status,
                      createdAt,
                      type,
                      vehicle,
                      serviceCenter,
                      user,
                      _id,
                    } = service;
                    let userPhone = `+${user.countryCode.toString()}${user.phone.toString()}`;
                    return (
                      <TableRow
                        key={_id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1 + page * rowsPerPage}
                        </TableCell>
                        <TableCell>{ticket}</TableCell>
                        <TableCell>
                          <h4
                            style={{
                              color: getServiceStatusColor(status),
                            }}
                          >
                            {status === "waiting"
                              ? "WAITING FOR APPROVAL"
                              : status === "payment"
                              ? "WAITING FOR DELIVERY"
                              : _.upperCase(status)}
                          </h4>
                        </TableCell>
                        <TableCell>{type}</TableCell>
                        <TableCell align="center">
                          <div className={classes.vehicleType}>
                            {vehicle.type === "car" ? (
                              <AiFillCar color="#fff" size={25} />
                            ) : (
                              <FaMotorcycle color="#fff" size={25} />
                            )}
                          </div>
                        </TableCell>
                        <TableCell>{serviceCenter.name}</TableCell>
                        <TableCell>{userPhone}</TableCell>
                        <TableCell>{moment(createdAt).calendar()}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => onClickEditService(service)}
                            aria-label="edit"
                            color="info"
                          >
                            <ViewIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => onClickDeleteService(service)}
                            aria-label="delete"
                            color="error"
                          >
                            <DeleteIcon />
                          </IconButton>{" "}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        10,
                        20,
                        50,
                        { label: "All", value: -1 },
                      ]}
                      count={services.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )}
        </>
      )}

      {mode && mode === "edit" && <CreateEditService mode={mode} />}
    </section>
  );
};

export default Services;
