import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import { GetAuthGuard } from "./Guards";
import { Admin } from "../components/Admin/Admin";
import { SocketContextProvider } from "../services/Socket/Socket.context";
import { AuthenticationContextProvider } from "../services/Authentication/Authentication.context";

import PageNotFound from "../components/NotFound/PageNotFound";
import Dashboard from "../components/Admin/Dashboard/Dashboard";
import { AnimatePresence } from "framer-motion";
import { ServiceCentersContextProvider } from "../services/ServiceCenters/ServiceCenters.context";
import ServiceCenters from "../components/Admin/Dashboard/ServiceCenters/ServiceCenters";
import { CouponCodesContextProvider } from "../services/CouponCodes/CouponCodes.context";
import CouponCodes from "../components/Admin/Dashboard/CouponCodes/CouponCodes";
import { MechanicContextProvider } from "../services/Mechanic/Mechanic.context";
import Mechanics from "../components/Admin/Dashboard/Mechanics/Mechanics";
import Services from "../components/Admin/Dashboard/Services/Services";
import { ServicesContextProvider } from "../services/Services/Services.context";
import { CategoriesAndProductsContextProvider } from "../services/CategoriesAndProducts/CategoriesAndProducts.context";
import Categories from "../components/Admin/Dashboard/Categories/Categories";
import { PaymentsContextProvider } from "../services/Payments/Payments.context";
import Payments from "../components/Admin/Dashboard/Payments/Payments";
import Products from "../components/Admin/Dashboard/Products/Products";
import { DashboardContextProvider } from "../services/Dashboard/Dashboard.context";
import MainDashboard from "../components/Admin/Dashboard/MainDashboard/MainDashboard";
import Orders from "../components/Admin/Dashboard/Orders/Orders";
import { OrdersContextProvider } from "../services/Orders/Orders.context";
import Home from "../components/Home/Home";

const Layout = (props) => {
  const location = useLocation();

  const ServiceCentersElement = ({ title }) => {
    return (
      <ServiceCentersContextProvider>
        <ServiceCenters title={title} />
      </ServiceCentersContextProvider>
    );
  };

  const CouponCodesElement = ({ title }) => {
    return (
      <CouponCodesContextProvider>
        <CouponCodes title={title} />
      </CouponCodesContextProvider>
    );
  };

  const MechanicsElement = ({ title }) => {
    return (
      <ServiceCentersContextProvider>
        <MechanicContextProvider>
          <Mechanics title={title} />
        </MechanicContextProvider>
      </ServiceCentersContextProvider>
    );
  };

  const ServicesElement = ({ title }) => {
    return (
      <ServiceCentersContextProvider>
        <ServicesContextProvider>
          <Services title={title} />
        </ServicesContextProvider>
      </ServiceCentersContextProvider>
    );
  };

  const CategoriesElement = ({ title }) => {
    return (
      <CategoriesAndProductsContextProvider>
        <Categories title={title} />
      </CategoriesAndProductsContextProvider>
    );
  };

  const ProductsElement = ({ title }) => {
    return (
      <CategoriesAndProductsContextProvider>
        <Products title={title} />
      </CategoriesAndProductsContextProvider>
    );
  };

  const PaymentsElement = ({ title }) => {
    return (
      <PaymentsContextProvider>
        <Payments title={title} />
      </PaymentsContextProvider>
    );
  };

  const DashboardElement = ({ title }) => {
    return (
      <DashboardContextProvider>
        <MainDashboard title={title} />
      </DashboardContextProvider>
    );
  };

  const OrdersElement = ({ title }) => {
    return (
      <OrdersContextProvider>
        <Orders title={title} />
      </OrdersContextProvider>
    );
  };

  return (
    <SocketContextProvider>
      <AuthenticationContextProvider>
        <AnimatePresence mode="wait">
          <Routes key={location.pathname} location={location}>
            <Route path="/" element={<Home title="Mechanic Pro" />} />
            <Route path="/admin" element={<Admin title="Sign In" />} />

            <Route
              path="/dashboard"
              element={
                <GetAuthGuard
                  component={<Dashboard title="Dashboard" />}
                  to={"/admin"}
                />
              }
            >
              <Route
                path="home"
                element={<DashboardElement title="Dashboard Home" />}
              />
              <Route
                path="service-centers"
                element={<ServiceCentersElement title="Service Centers" />}
              />
              <Route
                path="coupon-codes"
                element={<CouponCodesElement title="Coupon Codes" />}
              />
              <Route
                path="mechanics"
                element={<MechanicsElement title="Mechanics" />}
              />
              <Route
                path="services"
                element={<ServicesElement title="Services" />}
              />
              <Route
                path="categories"
                element={<CategoriesElement title="Categories" />}
              />
              <Route
                path="products"
                element={<ProductsElement title="Products" />}
              />
              <Route
                path="payments"
                element={<PaymentsElement title="Payments" />}
              />
              <Route path="orders" element={<OrdersElement title="Orders" />} />
            </Route>
            <Route path="*" element={<PageNotFound title="Page Not Found" />} />
          </Routes>

          <main>
            <Outlet />
          </main>
        </AnimatePresence>
      </AuthenticationContextProvider>
    </SocketContextProvider>
  );
};

export default Layout;
