import styles from "./Footer.module.css";

import Scroll from "react-scroll";
const ScrollElement = Scroll.Element;

export const Footer = (props) => {
  return (
    <ScrollElement className={styles.section} id="contact" name="contact">
      <nav className={`${styles.navbar} `}>
        <div className={styles.content}>
          <div className={styles.titleSection}>
            <h1 className={styles.title}>Mechanic Pro</h1>
            <h5 className={styles.subtitle}>
              Tired of the hassle of finding reliable mechanics for your car
              repairs? Look no further – MechanicPro is here to revolutionize
              your automotive service experience! Our mobile app connects
              vehicle owners with highly skilled and certified mechanics,
              ensuring top-notch repairs with a focus on trust and transparency.
            </h5>
          </div>
          <div className={styles.contactSection}>
            <h1 className={styles.title}>Contact us</h1>
            <h5 className={styles.subtitle}>
              Do you have a question? Write us a word:{" "}
            </h5>
            <p className={styles.mail}>
              <a href="mailto:support@mechanicpro.in">support@mechanicpro.in</a>
            </p>

            <div className={styles.socialButtonsContainer}>
              <h1 className={styles.title}>Get in touch with us !</h1>
              <div className={styles.socialButtons}>
                <div className={`${styles.button} ${styles.website}`}>
                  <a href="https://mechanicpro.in" target="__blank">
                    <img src={require("../../../assets/icons/website.png")} />
                  </a>
                </div>

                <div className={`${styles.button} ${styles.website}`}>
                  <a href="https://wa.me/916301010883" target="__blank">
                    <img src={require("../../../assets/icons/whatsapp.png")} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.copyRightContainer}>
          <p className={styles.copyright}>
            Copyright &#169; 2020-{new Date().getFullYear()} SUNIL KUMAR
            PASUPULETI (Webwizard). All rights reserved
          </p>
        </div>
      </nav>
    </ScrollElement>
  );
};
