/* eslint-disable react/jsx-no-duplicate-props */
import { useContext, useEffect, useState } from "react";
import {
  NotFoundContainer,
  NotFoundContainerImage,
  NotFoundText,
} from "../../../../styles";
import NoDataImg from "../../../../assets/no_data.png";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import ViewIcon from "@mui/icons-material/RemoveRedEye";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { PaymentsContext } from "../../../../services/Payments/Payments.context";
import { getCurrencyLocaleString } from "../../../../utility/helper";

const Payments = ({ title }) => {
  const { onGetPayments } = useContext(PaymentsContext);
  const [payments, setPayments] = useState([]);
  const [orgPayments, setOrgPayments] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [payment, setPayment] = useState(null);
  const location = useLocation();

  useEffect(() => {
    document.title = title;
    getPayments();
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paymentId = queryParams.get("payment");
    if (payments.length > 0 && paymentId) {
      let paymentItem = payments.find((p) => p._id === paymentId);
      if (paymentItem) {
        setPayment(paymentItem);
        setShowModal(true);
      }
    }
  }, [location.search, payments]);

  const getPayments = () => {
    onGetPayments(
      (result) => {
        if (result && result.payments) {
          setPayments(result.payments);
          setOrgPayments(result.payments);
        }
      },
      false,
      false
    );
  };

  const onChangeSearchKeyword = (e) => {
    let value = e.target.value;
    setSearchKeyword(value);
    let filtered = orgPayments;
    if (value) {
      value = value.toLowerCase();
      let finalPayments = _.cloneDeep(orgPayments);
      filtered = finalPayments.filter((payment) => {
        let {
          payment_id,
          order_id,
          paymentType,
          method,
          vpa,
          bank,
          totalAmount,
        } = payment;
        let paymentIdFound = payment_id?.toLowerCase().includes(value);
        let orderIdFound = order_id?.toLowerCase().includes(value);
        let paymentTypeFound = paymentType?.toLowerCase().includes(value);
        let methodFound = method?.toLowerCase().includes(value);
        let vpaFound = vpa?.toLowerCase().includes(value);
        let bankFound = bank?.toLowerCase().includes(value);
        let totalAmountFound = totalAmount?.toLowerCase().includes(value);
        return (
          paymentIdFound ||
          orderIdFound ||
          paymentTypeFound ||
          methodFound ||
          vpaFound ||
          bankFound ||
          totalAmountFound
        );
      });
    }
    setPayments(filtered);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [sort, setSort] = useState({
    type: "desc",
    field: null,
  });

  const onChangeSorting = (fieldToSort) => {
    var currentPayments = payments;
    let type = sort.type === "asc" ? "desc" : "asc";
    let fields = ["payment_id", "order_id"];
    if (fields.includes(fieldToSort)) {
      let sortedPayments = _.orderBy(currentPayments, fieldToSort, type);
      setSort((p) => ({
        ...p,
        type: type,
        field: fieldToSort,
      }));
      setPayments(sortedPayments);
    }
  };

  const onClickView = (payment) => {
    setPayment(payment);
    setShowModal(true);
  };

  const onHideDialog = () => {
    setPayment(null);
    setShowModal(false);
  };

  return (
    <section>
      {orgPayments && orgPayments.length > 0 && (
        <TextField
          margin="normal"
          fullWidth
          id="search"
          variant="standard"
          label="Search By Keyword"
          name="search"
          value={searchKeyword}
          onChange={onChangeSearchKeyword}
        />
      )}

      {payments.length === 0 && (
        <NotFoundContainer>
          <div>
            <NotFoundText>No Payments Found</NotFoundText>
            <NotFoundContainerImage src={NoDataImg} alt="..." />
          </div>
        </NotFoundContainer>
      )}

      {payments.length > 0 && (
        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>S.NO</TableCell>
                <TableCell>
                  {" "}
                  <TableSortLabel
                    direction={
                      sort.type && sort.type === "desc" ? "asc" : "desc"
                    }
                    active
                    onClick={() => onChangeSorting("payment_id")}
                  >
                    PAYMENT ID
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    direction={
                      sort.type && sort.type === "desc" ? "asc" : "desc"
                    }
                    active
                    onClick={() => onChangeSorting("order_id")}
                  >
                    ORDER ID
                  </TableSortLabel>
                </TableCell>
                <TableCell>Payment For</TableCell>
                <TableCell>By User</TableCell>
                <TableCell>Method</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? payments.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : payments
              ).map((payment, index) => {
                let { payment_id, order_id, user, method, amount, _id, type } =
                  payment;
                let phone = `+${user?.countryCode.toString()}${user?.phone.toString()}`;

                return (
                  <TableRow
                    key={_id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1 + page * rowsPerPage}
                    </TableCell>
                    <TableCell>{payment_id}</TableCell>
                    <TableCell>{order_id}</TableCell>
                    <TableCell>{_.toUpper(type)}</TableCell>
                    <TableCell>{phone}</TableCell>
                    <TableCell>{method}</TableCell>
                    <TableCell>
                      {getCurrencyLocaleString(amount / 100)}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => onClickView(payment)}
                        color="success"
                      >
                        <ViewIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50, { label: "All", value: -1 }]}
                  count={payments.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}

      <Dialog open={showModal} onClose={onHideDialog} fullWidth>
        <DialogTitle>Payment Details</DialogTitle>
        <DialogContent>
          {payment && (
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell>Payment Id </TableCell>
                    <TableCell>
                      <strong>{payment.payment_id}</strong>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Order Id </TableCell>
                    <TableCell>
                      <strong>{payment.order_id}</strong>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Payment Mode </TableCell>
                    <TableCell>{_.toUpper(payment.paymentType)}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Method </TableCell>
                    <TableCell>{_.toUpper(payment.method)}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Payment For </TableCell>
                    <TableCell>{_.toUpper(payment.type)}</TableCell>
                  </TableRow>

                  {payment.description && (
                    <TableRow>
                      <TableCell>Note </TableCell>
                      <TableCell>{payment.description}</TableCell>
                    </TableRow>
                  )}

                  {payment.bank && (
                    <TableRow>
                      <TableCell>Bank </TableCell>
                      <TableCell>{payment.bank}</TableCell>
                    </TableRow>
                  )}

                  {payment.vpa && (
                    <TableRow>
                      <TableCell>UPI </TableCell>
                      <TableCell>{payment.vpa}</TableCell>
                    </TableRow>
                  )}

                  {payment.wallet && (
                    <TableRow>
                      <TableCell>Wallet </TableCell>
                      <TableCell>{payment.wallet}</TableCell>
                    </TableRow>
                  )}

                  {payment.card_id && (
                    <TableRow>
                      <TableCell>Card Id </TableCell>
                      <TableCell>{payment.card_id}</TableCell>
                    </TableRow>
                  )}

                  {payment.couponCode && (
                    <TableRow>
                      <TableCell>Coupon Code Applied</TableCell>
                      <TableCell>
                        <strong>{payment.couponCode.code}</strong>
                        <br />
                        {payment.couponCode.description}
                      </TableCell>
                    </TableRow>
                  )}

                  <TableRow>
                    <TableCell>Total Bill </TableCell>
                    <TableCell>
                      {" "}
                      {getCurrencyLocaleString(payment.totalAmount)}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Discount</TableCell>
                    <TableCell
                      style={{
                        color: "green",
                      }}
                    >
                      - {getCurrencyLocaleString(payment.discount)}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <strong>Paid</strong>
                    </TableCell>
                    <TableCell>
                      <strong>
                        {getCurrencyLocaleString(payment.amount / 100)}
                      </strong>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onHideDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </section>
  );
};

export default Payments;
