/* eslint-disable react/jsx-no-duplicate-props */
import { useContext, useEffect, useState } from "react";
import {
  NotFoundContainer,
  NotFoundContainerImage,
  NotFoundText,
} from "../../../../styles";
import NoDataImg from "../../../../assets/no_data.png";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/CheckCircle";
import { FaPlus } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { SocketContext } from "../../../../services/Socket/Socket.context";
import Swal from "sweetalert2";
import { getCurrencyLocaleString } from "../../../../utility/helper";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import { CategoriesAndProductsContext } from "../../../../services/CategoriesAndProducts/CategoriesAndProducts.context";
import CreateEditProduct from "./CreateEditProduct";

const Products = ({ title }) => {
  const { onGetProducts, onDeleteProduct } = useContext(
    CategoriesAndProductsContext
  );
  const { socket, onFetchEvent, onEmitEvent } = useContext(SocketContext);
  const [products, setProducts] = useState([]);
  const [orgProducts, setOrgProducts] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const location = useLocation();
  const [mode, setMode] = useState(null);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    document.title = title;
    getProducts();
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get("mode");
    if (mode) {
      setMode(mode);
    }
  }, [location.search]);

  useEffect(() => {
    if (socket) {
      const eventHandler = (data) => {
        getProducts();
      };
      onFetchEvent("refreshProducts", eventHandler);
      return () => {
        socket?.off("refreshProducts", eventHandler);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onFetchEvent, socket]);

  const getProducts = () => {
    onGetProducts(
      (result) => {
        if (result && result.products) {
          setProducts(result.products);
          setOrgProducts(result.products);
        }
      },
      false,
      false
    );
  };

  const onChangeSearchKeyword = (e) => {
    let value = e.target.value;
    setSearchKeyword(value);
    let filtered = orgProducts;
    if (value) {
      value = value.toLowerCase();
      let finalProducts = _.cloneDeep(orgProducts);
      filtered = finalProducts.filter((serviceCenter) => {
        let { name, category, finalAmount } = serviceCenter;
        let nameFound = name.toLowerCase().includes(value);
        let categoryFound = category?.name?.toLowerCase().includes(value);
        let finalAmountFound = finalAmount.toString().includes(value);

        return nameFound || categoryFound || finalAmountFound;
      });
    }
    setProducts(filtered);
  };

  const onClickEditProduct = (product) => {
    navigate("?mode=edit&id=" + product._id);
  };

  const onClickDeleteProduct = (product) => {
    Swal.fire({
      title: "Are you sure to delete?",
      text: `${product.name}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteProduct(product._id, (result) => {
          onEmitEvent("refreshProducts");
        });
      }
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [sort, setSort] = useState({
    type: "desc",
    field: null,
  });

  const onChangeSorting = (fieldToSort) => {
    var currentProducts = products;
    let type = sort.type === "asc" ? "desc" : "asc";
    let fields = ["name", "finalAmount"];
    if (fields.includes(fieldToSort)) {
      let sortedProducts = _.orderBy(currentProducts, fieldToSort, type);
      setSort((p) => ({
        ...p,
        type: type,
        field: fieldToSort,
      }));
      setOrgProducts(sortedProducts);
    }
  };

  return (
    <section>
      {!mode && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Link to={"?mode=create"}>
              <Button variant="contained" startIcon={<FaPlus />}>
                Add Product
              </Button>
            </Link>
          </Box>

          {orgProducts && orgProducts.length > 0 && (
            <TextField
              margin="normal"
              fullWidth
              id="search"
              variant="standard"
              label="Search By Keyword"
              name="search"
              value={searchKeyword}
              onChange={onChangeSearchKeyword}
            />
          )}

          {products.length === 0 && (
            <NotFoundContainer>
              <div>
                <NotFoundText>No Products Found</NotFoundText>
                <NotFoundContainerImage src={NoDataImg} alt="..." />
              </div>
            </NotFoundContainer>
          )}

          {products.length > 0 && (
            <TableContainer component={Paper} sx={{ mt: 4 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>S.NO</TableCell>
                    <TableCell>
                      {" "}
                      <TableSortLabel
                        direction={
                          sort.type && sort.type === "desc" ? "asc" : "desc"
                        }
                        active
                        onClick={() => onChangeSorting("name")}
                      >
                        NAME
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Cod Available</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? products.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : products
                  ).map((product, index) => {
                    let {
                      name,
                      finalAmount,
                      _id,
                      codAvailable,
                      category,
                      quantity,
                      createdAt,
                    } = product;

                    return (
                      <TableRow
                        key={_id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1 + page * rowsPerPage}
                        </TableCell>
                        <TableCell>{name}</TableCell>
                        <TableCell>{category.name}</TableCell>
                        <TableCell>{quantity}</TableCell>
                        <TableCell>
                          {getCurrencyLocaleString(finalAmount)}
                        </TableCell>
                        <TableCell>
                          {codAvailable && (
                            <CheckIcon
                              sx={{
                                color: "#63BA60",
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {moment(createdAt).format("MMM DD, YYYY - hh:mm A")}
                        </TableCell>

                        <TableCell>
                          <IconButton
                            onClick={() => onClickEditProduct(product)}
                            aria-label="edit"
                            color="info"
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => onClickDeleteProduct(product)}
                            aria-label="delete"
                            color="error"
                          >
                            <DeleteIcon />
                          </IconButton>{" "}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        10,
                        20,
                        50,
                        { label: "All", value: -1 },
                      ]}
                      count={products.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )}
        </>
      )}
      {mode && <CreateEditProduct mode={mode} />}
    </section>
  );
};

export default Products;
