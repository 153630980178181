/* eslint-disable react-hooks/exhaustive-deps */
import { createContext } from "react";

import useHttp from "../../hooks/useHttp";

export const ServicesContext = createContext({
  onGetServices: (callback, loader, notify) => null,
  onGetService: (serviceId, callback, errorCallBack, loader, notify) => null,
  onDeleteService: (serviceId, callback, errorCallBack, loader, notify) => null,
  onUpdateService: (serviceId, data, callback, errorCallBack, loader, notify) =>
    null,
});

export const ServicesContextProvider = ({ children }) => {
  const { sendRequest } = useHttp();

  const baseUrl = "/admin/";

  const onGetServices = async (
    callback = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + "service",
      },
      {
        successCallback: callback,
      },
      loader,
      notify
    );
  };

  const onGetService = async (
    serviceId,
    callback = () => null,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + "service/" + serviceId,
      },
      {
        successCallback: callback,
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  const onUpdateService = async (
    serviceId,
    data,
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + `service/` + serviceId,
        type: "PUT",
        data: data,
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  const onDeleteService = async (
    serviceId,
    callback = () => null,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + "service/" + serviceId,
        method: "DELETE",
      },
      {
        successCallback: callback,
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  return (
    <ServicesContext.Provider
      value={{
        onGetService,
        onGetServices,
        onDeleteService,
        onUpdateService,
      }}
    >
      {children}
    </ServicesContext.Provider>
  );
};
