/* eslint-disable react/jsx-no-duplicate-props */
import { useContext, useEffect, useState } from "react";
import {
  NotFoundContainer,
  NotFoundContainerImage,
  NotFoundText,
} from "../../../../styles";
import NoDataImg from "../../../../assets/no_data.png";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import ViewIcon from "@mui/icons-material/RemoveRedEye";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { getCurrencyLocaleString } from "../../../../utility/helper";
import { OrdersContext } from "../../../../services/Orders/Orders.context";
import EditOrder from "./EditOrder";

const Orders = ({ title }) => {
  const { onGetOrders } = useContext(OrdersContext);
  const [orders, setOrders] = useState([]);
  const [orgOrders, setOrgOrders] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const location = useLocation();
  const [mode, setMode] = useState(null);

  useEffect(() => {
    document.title = title;
    const queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get("mode");
    if (mode) {
      setMode(mode);
    } else {
      getOrders();
    }
  }, [location.search]);

  const getOrders = () => {
    onGetOrders(
      (result) => {
        if (result && result.orders) {
          setOrders(result.orders);
          setOrgOrders(result.orders);
        }
      },
      false,
      false
    );
  };

  const onChangeSearchKeyword = (e) => {
    let value = e.target.value;
    setSearchKeyword(value);
    let filtered = orgOrders;
    if (value) {
      value = value.toLowerCase();
      let finalOrders = _.cloneDeep(orgOrders);
      filtered = finalOrders.filter((order) => {
        let { order_id, user, payment } = order;
        let orderIdFound = order_id?.toLowerCase().includes(value);
        let userFound = user?.phone?.toString().includes(value);
        let totalAmountFound = (payment?.amount / 100)
          .toString()
          .includes(value);
        return orderIdFound || userFound || totalAmountFound;
      });
    }
    setOrders(filtered);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [sort, setSort] = useState({
    type: "desc",
    field: null,
  });

  const onChangeSorting = (fieldToSort) => {
    var currentOrders = orders;
    let type = sort.type === "asc" ? "desc" : "asc";
    let fields = ["order_id"];
    if (fields.includes(fieldToSort)) {
      let sortedOrders = _.orderBy(currentOrders, fieldToSort, type);
      setSort((p) => ({
        ...p,
        type: type,
        field: fieldToSort,
      }));
      setOrders(sortedOrders);
    }
  };

  const onClickEditService = (order) => {
    navigate("?mode=edit&id=" + order._id);
  };

  return (
    <section>
      {!mode && (
        <>
          {orgOrders && orgOrders.length > 0 && (
            <TextField
              margin="normal"
              fullWidth
              id="search"
              variant="standard"
              label="Search By Keyword"
              name="search"
              value={searchKeyword}
              onChange={onChangeSearchKeyword}
            />
          )}

          {orders.length === 0 && (
            <NotFoundContainer>
              <div>
                <NotFoundText>No Orders Found</NotFoundText>
                <NotFoundContainerImage src={NoDataImg} alt="..." />
              </div>
            </NotFoundContainer>
          )}

          {orders.length > 0 && (
            <TableContainer component={Paper} sx={{ mt: 4 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>S.NO</TableCell>
                    <TableCell>
                      <TableSortLabel
                        direction={
                          sort.type && sort.type === "desc" ? "asc" : "desc"
                        }
                        active
                        onClick={() => onChangeSorting("order_id")}
                      >
                        ORDER ID
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Items Ordered</TableCell>
                    <TableCell>User</TableCell>
                    <TableCell>Total Amount</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? orders.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : orders
                  ).map((order, index) => {
                    let { order_id, user, total, items, _id, payment } = order;
                    let phone = `+${user?.countryCode.toString()}${user?.phone.toString()}`;

                    return (
                      <TableRow
                        key={_id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1 + page * rowsPerPage}
                        </TableCell>
                        <TableCell>{order_id}</TableCell>
                        <TableCell>{items.length}</TableCell>
                        <TableCell>{phone}</TableCell>
                        <TableCell>
                          <b>{getCurrencyLocaleString(payment.amount / 100)}</b>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => onClickEditService(order)}
                            aria-label="edit"
                            color="info"
                          >
                            <ViewIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        10,
                        20,
                        50,
                        { label: "All", value: -1 },
                      ]}
                      count={orders.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )}
        </>
      )}

      {mode && mode === "edit" && <EditOrder mode={mode} />}
    </section>
  );
};

export default Orders;
