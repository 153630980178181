/* eslint-disable react-hooks/exhaustive-deps */
import { createContext } from "react";

import useHttp from "../../hooks/useHttp";

export const OrdersContext = createContext({
  onGetOrders: (callback, loader, notify) => null,
  onGetOrder: (orderId, callback, loader, notify) => null,
  onEditOrder: (orderId, data, callback, loader, notify) => null,
});

export const OrdersContextProvider = ({ children }) => {
  const { sendRequest } = useHttp();

  const baseUrl = "/admin/";

  const onGetOrders = async (
    callback = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + "orders",
      },
      {
        successCallback: callback,
      },
      loader,
      notify
    );
  };

  const onGetOrder = async (
    orderId,
    callback = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + "orders/" + orderId,
      },
      {
        successCallback: callback,
      },
      loader,
      notify
    );
  };

  const onEditOrder = async (
    orderId,
    data,
    callback = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + "orders/" + orderId,
        type: "PUT",
        data: data,
      },
      {
        successCallback: callback,
      },
      loader,
      notify
    );
  };

  return (
    <OrdersContext.Provider
      value={{
        onGetOrders,
        onGetOrder,
        onEditOrder,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};
