/* eslint-disable react-hooks/exhaustive-deps */
import { createContext } from "react";

import useHttp from "../../hooks/useHttp";

export const CouponCodesContext = createContext({
  onCreateCouponCode: (data, callback, errorCallBack, loader, notify) => null,
  onUpdateCouponCode: (
    couponCodeId,
    data,
    callback,
    errorCallBack,
    loader,
    notify
  ) => null,
  onGetCouponCodes: (callback, loader, notify) => null,
  onGetCouponCode: (couponCodeId, callback, errorCallBack, loader, notify) =>
    null,
  onDeleteCouponCode: (couponCodeId, callback, errorCallBack, loader, notify) =>
    null,
});

export const CouponCodesContextProvider = ({ children }) => {
  const { sendRequest } = useHttp();

  const baseUrl = "/";

  const onCreateCouponCode = async (
    data,
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + `coupon/`,
        type: "POST",
        data: data,
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  const onUpdateCouponCode = async (
    couponCodeId,
    data,
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + `coupon/` + couponCodeId,
        type: "PUT",
        data: data,
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  const onGetCouponCodes = async (
    callback = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + "coupon/admin",
      },
      {
        successCallback: callback,
      },
      loader,
      notify
    );
  };

  const onGetCouponCode = async (
    couponCodeId,
    callback = () => null,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + "coupon/" + couponCodeId,
      },
      {
        successCallback: callback,
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  const onDeleteCouponCode = async (
    couponCodeId,
    callback = () => null,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + "coupon/" + couponCodeId,
        type: "DELETE",
      },
      {
        successCallback: callback,
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  return (
    <CouponCodesContext.Provider
      value={{
        onCreateCouponCode,
        onGetCouponCode,
        onGetCouponCodes,
        onDeleteCouponCode,
        onUpdateCouponCode,
      }}
    >
      {children}
    </CouponCodesContext.Provider>
  );
};
