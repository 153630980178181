import styles from "./Features.module.css";
import Scroll from "react-scroll";
import { useScroll, useTransform, motion } from "framer-motion";
import { useRef } from "react";

const ScrollElement = Scroll.Element;

export const Features = ({}) => {
  let ref = useRef();

  return (
    <motion.div className={styles.section} ref={ref}>
      <ScrollElement id="features" name="features">
        <h1 className={styles.title}>Features offered by us</h1>
        <h5 className={styles.subtitle}>
          Just the right features to help you stay on track!
        </h5>
        <div className={styles.features}>
          <div className={styles.feature}>
            <div className={styles.icon}>
              <i className={`fa-solid fa-mobile-screen`}></i>
            </div>
            <h5 className={styles.title}>Easy to use</h5>
            <p className={styles.subtitle}>
              Easy, fast app for auto services, repairs, & parts. Trusted mechs,
              service history, comm. Features. Convenient & efficient.
            </p>
          </div>
          <div className={styles.feature}>
            <div className={styles.icon}>
              <i className={`fa-solid fa-lock`}></i>
            </div>
            <h5 className={styles.title}>Privacy</h5>
            <p className={styles.subtitle}>
              Your data is protected with top-level encryption, ensuring
              exclusive access and preserving your privacy and confidentiality
              as our utmost priority.
            </p>
          </div>

          <div className={styles.feature}>
            <div className={styles.icon}>
              <i className={`fa-solid fa-message`}></i>
            </div>
            <h5 className={styles.title}>Seamless SMS Updates.</h5>
            <p className={styles.subtitle}>
              Receive up-to-date service updates via SMS. Say goodbye to manual
              input and enjoy convenience!
            </p>
          </div>

          <div className={styles.feature}>
            <div className={styles.icon}>
              <i className={`fa-solid fa-sync`}></i>
            </div>
            <h5 className={styles.title}>Service History & Payments</h5>
            <p className={styles.subtitle}>
              Keep track of all your past service histories and payments in one
              place with our app. Say goodbye to disorganized records and enjoy
              convenience!
            </p>
          </div>

          <div className={styles.feature}>
            <div className={styles.icon}>
              <i className={`fa-solid fa-gear`}></i>
            </div>
            <h5 className={styles.title}>Spares & Accessories</h5>
            <p className={styles.subtitle}>
              Wide range of spare parts. Our app offers a vast selection of
              spare parts for all types of vehicles, including COD (Cash on
              Delivery) options. Whether you need engine parts, transmission
              components, or electrical accessories, we've got you covered!
            </p>
          </div>
        </div>
      </ScrollElement>
    </motion.div>
  );
};
