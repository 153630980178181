/* eslint-disable react-hooks/exhaustive-deps */
import { createContext } from "react";

import useHttp from "../../hooks/useHttp";

export const CategoriesAndProductsContext = createContext({
  onCreateCategory: (data, callback, errorCallBack, loader, notify) => null,
  onUpdateCategory: (
    categoryId,
    data,
    callback,
    errorCallBack,
    loader,
    notify
  ) => null,
  onGetCategories: (callback, loader, notify) => null,
  onGetCategory: (categoryId, callback, errorCallBack, loader, notify) => null,
  onDeleteCategory: (categoryId, callback, errorCallBack, loader, notify) =>
    null,

  onCreateProduct: (data, callback, errorCallBack, loader, notify) => null,
  onUpdateProduct: (productId, data, callback, errorCallBack, loader, notify) =>
    null,
  onGetProducts: (callback, loader, notify) => null,
  onGetProduct: (productId, callback, errorCallBack, loader, notify) => null,
  onDeleteProduct: (productId, callback, errorCallBack, loader, notify) => null,
  onDeleteProductImage: (productId, callback, errorCallBack, loader, notify) =>
    null,
});

export const CategoriesAndProductsContextProvider = ({ children }) => {
  const { sendRequest } = useHttp();

  const baseUrl = "/";

  const onCreateCategory = async (
    data,
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + `category/`,
        type: "POST",
        data: data,
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  const onUpdateCategory = async (
    categoryId,
    data,
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + `category/` + categoryId,
        type: "PUT",
        data: data,
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  const onGetCategories = async (
    callback = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + "category",
      },
      {
        successCallback: callback,
      },
      loader,
      notify
    );
  };

  const onGetCategory = async (
    categoryId,
    callback = () => null,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + "category/" + categoryId,
      },
      {
        successCallback: callback,
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  const onDeleteCategory = async (
    categoryId,
    callback = () => null,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + "category/" + categoryId,
        type: "DELETE",
      },
      {
        successCallback: callback,
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  const onCreateProduct = async (
    data,
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + `product/`,
        type: "POST",
        data: data,
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  const onUpdateProduct = async (
    productId,
    data,
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + `product/` + productId,
        type: "PUT",
        data: data,
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  const onGetProducts = async (
    callback = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + "product",
      },
      {
        successCallback: callback,
      },
      loader,
      notify
    );
  };

  const onGetProduct = async (
    productId,
    callback = () => null,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + "product/" + productId,
      },
      {
        successCallback: callback,
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  const onDeleteProduct = async (
    productId,
    callback = () => null,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + "product/" + productId,
        type: "DELETE",
      },
      {
        successCallback: callback,
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  const onDeleteProductImage = async (
    productId,
    data,
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + `product/` + productId + "/removeImage",
        type: "PUT",
        data: data,
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  return (
    <CategoriesAndProductsContext.Provider
      value={{
        onCreateCategory,
        onGetCategories,
        onGetCategory,
        onDeleteCategory,
        onUpdateCategory,

        onCreateProduct,
        onGetProduct,
        onGetProducts,
        onDeleteProduct,
        onDeleteProductImage,
        onUpdateProduct,
      }}
    >
      {children}
    </CategoriesAndProductsContext.Provider>
  );
};
