/* eslint-disable react/jsx-no-duplicate-props */
import { useContext, useEffect, useState } from "react";
import {
  NotFoundContainer,
  NotFoundContainerImage,
  NotFoundText,
} from "../../../../styles";
import NoDataImg from "../../../../assets/no_data.png";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/CheckCircle";
import { FaPlus } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { SocketContext } from "../../../../services/Socket/Socket.context";
import Swal from "sweetalert2";
import { CouponCodesContext } from "../../../../services/CouponCodes/CouponCodes.context";
import CreateEditCouponCode from "./CreateEditCouponCode";
import { getCurrencyLocaleString } from "../../../../utility/helper";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";

const CouponCodes = ({ title }) => {
  const { onGetCouponCodes, onDeleteCouponCode } =
    useContext(CouponCodesContext);
  const { socket, onFetchEvent, onEmitEvent } = useContext(SocketContext);
  const [couponCodes, setCouponCodes] = useState([]);
  const [orgCouponCodes, setOrgCouponCodes] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const location = useLocation();
  const [mode, setMode] = useState(null);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    document.title = title;
    getCouponCodes();
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get("mode");
    if (mode) {
      setMode(mode);
    }
  }, [location.search]);

  useEffect(() => {
    if (socket) {
      const eventHandler = (data) => {
        getCouponCodes();
      };
      onFetchEvent("refreshCouponCodes", eventHandler);
      return () => {
        socket?.off("refreshCouponCodes", eventHandler);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onFetchEvent, socket]);

  const getCouponCodes = () => {
    onGetCouponCodes(
      (result) => {
        if (result && result.couponCodes) {
          setCouponCodes(result.couponCodes);
          setOrgCouponCodes(result.couponCodes);
        }
      },
      false,
      false
    );
  };

  const onChangeSearchKeyword = (e) => {
    let value = e.target.value;
    setSearchKeyword(value);
    let filtered = orgCouponCodes;
    if (value) {
      value = value.toLowerCase();
      let finalCouponCodes = _.cloneDeep(orgCouponCodes);
      filtered = finalCouponCodes.filter((serviceCenter) => {
        let { code, description } = serviceCenter;
        let codeFound = code.toLowerCase().includes(value);
        let descFound = description
          ? description.toLowerCase().includes(value)
          : false;
        return codeFound || descFound;
      });
    }
    setCouponCodes(filtered);
  };

  const onClickEditCouponCode = (couponCode) => {
    navigate("?mode=edit&id=" + couponCode._id);
  };

  const onClickDeleteCouponCode = (couponCode) => {
    Swal.fire({
      title: "Are you sure to delete?",
      text: `${couponCode.code}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteCouponCode(couponCode._id, (result) => {
          onEmitEvent("refreshCouponCodes");
        });
      }
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [sort, setSort] = useState({
    type: "desc",
    field: null,
  });

  const onChangeSorting = (fieldToSort) => {
    var currentCouponCodes = couponCodes;
    let type = sort.type === "asc" ? "desc" : "asc";
    let fields = ["code"];
    if (fields.includes(fieldToSort)) {
      let sortedCouponCodes = _.orderBy(currentCouponCodes, fieldToSort, type);
      setSort((p) => ({
        ...p,
        type: type,
        field: fieldToSort,
      }));
      setOrgCouponCodes(sortedCouponCodes);
    }
  };

  return (
    <section>
      {!mode && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Link to={"?mode=create"}>
              <Button variant="contained" startIcon={<FaPlus />}>
                Add Coupon Code
              </Button>
            </Link>
          </Box>

          {orgCouponCodes && orgCouponCodes.length > 0 && (
            <TextField
              margin="normal"
              fullWidth
              id="search"
              variant="standard"
              label="Search By Keyword"
              name="search"
              value={searchKeyword}
              onChange={onChangeSearchKeyword}
            />
          )}

          {couponCodes.length === 0 && (
            <NotFoundContainer>
              <div>
                <NotFoundText>No Coupon Codes Found</NotFoundText>
                <NotFoundContainerImage src={NoDataImg} alt="..." />
              </div>
            </NotFoundContainer>
          )}

          {couponCodes.length > 0 && (
            <TableContainer component={Paper} sx={{ mt: 4 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>S.NO</TableCell>
                    <TableCell>
                      {" "}
                      <TableSortLabel
                        direction={
                          sort.type && sort.type === "desc" ? "asc" : "desc"
                        }
                        active
                        onClick={() => onChangeSorting("code")}
                      >
                        CODE
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>description</TableCell>
                    <TableCell>Applicable On</TableCell>
                    <TableCell>Discount</TableCell>
                    <TableCell>Valid From</TableCell>
                    <TableCell>Valid Until</TableCell>
                    <TableCell>Visible</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? couponCodes.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : couponCodes
                  ).map((couponCode, index) => {
                    let {
                      code,
                      description,
                      _id,
                      type,
                      discount,
                      amount,
                      applicableOn,
                      visible,
                      validFrom,
                      validUntil,
                    } = couponCode;
                    applicableOn =
                      applicableOn === "bikeService"
                        ? "Bike Service"
                        : applicableOn === "carService"
                        ? "Car Service"
                        : applicableOn;
                    discount =
                      type === "discount"
                        ? `${discount}%`
                        : `${getCurrencyLocaleString(amount)}`;
                    validFrom = moment(validFrom).format(
                      "MMM DD, YYYY -  hh:mm A"
                    );
                    validUntil = moment(validUntil).format(
                      "MMM DD, YYYY -  hh:mm A"
                    );

                    return (
                      <TableRow
                        key={_id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1 + page * rowsPerPage}
                        </TableCell>
                        <TableCell>{code}</TableCell>
                        <TableCell>
                          {description.substring(0, 20) + "..."}
                        </TableCell>
                        <TableCell>{_.upperCase(applicableOn)}</TableCell>
                        <TableCell>{discount}</TableCell>
                        <TableCell>{validFrom}</TableCell>
                        <TableCell>{validUntil}</TableCell>
                        <TableCell>
                          {visible && (
                            <CheckIcon
                              sx={{
                                color: "#63BA60",
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => onClickEditCouponCode(couponCode)}
                            aria-label="edit"
                            color="info"
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => onClickDeleteCouponCode(couponCode)}
                            aria-label="delete"
                            color="error"
                          >
                            <DeleteIcon />
                          </IconButton>{" "}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        10,
                        20,
                        50,
                        { label: "All", value: -1 },
                      ]}
                      count={couponCodes.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )}
        </>
      )}
      {mode && <CreateEditCouponCode mode={mode} />}
    </section>
  );
};

export default CouponCodes;
