const currencyFormatter = new Intl.NumberFormat("en-In", {
  style: "currency",
  currency: "INR",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

let serviceStatusColors = {
  scheduled: "#3498db",
  waiting: "#f39c12",
  progress: "#9b59b6",
  completed: "#2ecc71",
  payment: "#2ECC71",
  cancelled: "#e74c3c",
  delivered: "#27ae60",
};

let orderStatusColors = {
  ordered: "#3498db",
  shipped: "#2ecc71",
  outForDelivery: "#f39c12",
  delivered: "#27ae60",
};

export const getOrderStatusColor = (status) => {
  return orderStatusColors[status];
};

export function getFirebaseAccessUrl(path = "") {
  let URL =
    process.env.REACT_APP_FIREBASE_STORAGE_URL +
    path.replaceAll("/", "%2f") +
    "?alt=media";
  return URL;
}

export const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export const convertFilesToBase64 = async (files) => {
  const base64Promises = Array.from(files).map((file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  });

  const base64Results = await Promise.all(base64Promises);
  return base64Results;
};

export function getCurrencyLocaleString(price) {
  let converted = currencyFormatter.format(price);
  return converted;
}

export const getServiceStatusColor = (status) => {
  return serviceStatusColors[status];
};
