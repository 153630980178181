import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ServiceCentersContext } from "../../../../services/ServiceCenters/ServiceCenters.context";
import { ServicesContext } from "../../../../services/Services/Services.context";
import { FaMotorcycle, FaWrench } from "react-icons/fa";
import _ from "lodash";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import {
  getCurrencyLocaleString,
  getServiceStatusColor,
} from "../../../../utility/helper";
import moment from "moment";
import classes from "./Services.module.css";
import { AiFillCar } from "react-icons/ai";
import { fromLatLng, setKey } from "react-geocode";

const errors = {
  ticketRequired: "Ticket required",
  typeRequired: "Service Type required",
  deliveryTypeRequired: "Delivery Type Required",
  cancelledReasonsRequired: "Cancellation reasons Required",
  serviceCenterRequired: "Please select service center",
};

const deliveryTypes = [
  {
    label: "Doorstep Delivery",
    value: "Doorstep Delivery",
  },
  {
    label: "Self Delivery",
    value: "Self Delivery",
  },
];

const commonInputFieldProps = {
  value: "",
  focused: false,
  error: false,
  errorMessage: "",
};

const defaultInputState = {
  ticket: {
    ...commonInputFieldProps,
  },
  type: {
    ...commonInputFieldProps,
  },
  deliveryType: {
    ...commonInputFieldProps,
  },
  cancelledReasons: {
    ...commonInputFieldProps,
  },
  serviceCenter: {
    ...commonInputFieldProps,
  },
  estimatedBill: {
    ...commonInputFieldProps,
  },
  bill: {
    ...commonInputFieldProps,
  },
  id: {
    ...commonInputFieldProps,
  },
};

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API;

const CreateEditService = ({ mode }) => {
  const [service, setService] = useState(null);
  const [inputs, setInputs] = useState(defaultInputState);
  const [loading, setLoading] = useState(false);
  const [formattedLocation, setFormattedLocation] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [serviceCenters, setServiceCenters] = useState([]);
  const { onUpdateService, onGetService } = useContext(ServicesContext);
  const { onGetServiceCenters } = useContext(ServiceCentersContext);
  let amountRegex = /^\d+(\.\d{1,2})?$/;
  let qntyRegex = /^[1-9]\d*$/;

  const navigate = useNavigate();

  setKey(GOOGLE_MAPS_API_KEY);

  useEffect(() => {
    getServiceCenters();
    if (mode) {
      let title = "Edit Service";
      document.title = title;
    }
    if (mode === "edit") {
      let editId = searchParams.get("id");
      if (!editId) {
        navigate("/dashboard/services");
        return;
      }
      if (editId) {
        onGetService(
          editId,
          (result) => {
            let serviceData = result.service;
            console.log(serviceData);
            setService(serviceData);
            if (serviceData) {
              let {
                ticket,
                type,
                serviceCenter,
                deliveryType,
                _id,
                estimatedBill,
                cancelledReasons,
                location,
                bill,
              } = serviceData;
              if (location && location.latitude && location.longitude) {
                getFormattedAddress(location);
              }
              setInputs((prevState) => ({
                ...prevState,
                ticket: {
                  ...commonInputFieldProps,
                  value: ticket,
                },
                type: {
                  ...commonInputFieldProps,
                  value: type,
                },
                cancelledReasons: {
                  ...commonInputFieldProps,
                  value: cancelledReasons || "",
                },
                deliveryType: {
                  ...commonInputFieldProps,
                  value: deliveryTypes.find((d) => d.value === deliveryType),
                },
                serviceCenter: {
                  ...commonInputFieldProps,
                  value: serviceCenter,
                },
                estimatedBill: {
                  ...commonInputFieldProps,
                  value: estimatedBill || null,
                },
                bill: {
                  ...commonInputFieldProps,
                  value: bill || null,
                },
                id: {
                  value: _id,
                },
              }));
            } else {
              navigate("/dashboard/services");
            }
          },
          () => {
            navigate("/dashboard/services");
          },
          false,
          false
        );
      }
    }
  }, [mode]);

  const onValueChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        error: false,
        errorMessage: "",
        value,
      },
    }));
  };

  const onBillValueChangeHandler = (type, key, index, value) => {
    let bill = inputs[type].value;
    let currentItems = bill.items;
    let currentItem = currentItems.find((c, i) => i === index);
    let totalAmount = 0;
    let finalAmount = 0;

    if (["quantity", "amount"].includes(key)) {
      let amount;
      let quantity;
      if (key === "amount") {
        amount = value;
        quantity = currentItem.quantity?.toString();
      }
      if (key === "quantity") {
        quantity = value;
        amount = currentItem.amount?.toString();
      }

      let qntyValid = qntyRegex.test(quantity);
      let amntValid = amountRegex.test(amount);

      if (!qntyValid) {
        currentItem.quantity = "";
      }
      if (!amntValid) {
        currentItem.amount = "";
      }

      if (qntyValid && amntValid) {
        totalAmount = (Number(amount) * Number(quantity)).toString();
      } else if (qntyValid && !amntValid) {
        amount = "";
      } else if (amntValid && !qntyValid) {
        quantity = "";
        totalAmount = amount;
      } else {
        totalAmount = "";
      }

      currentItem.quantity = quantity;
      currentItem.amount = amount;
      currentItem.error = false;
      currentItem.errorMessage = "";
    }

    currentItem[key] = value;
    currentItems[index] = currentItem;
    bill.items = currentItems;
    currentItem.totalAmount = totalAmount;

    currentItems.forEach((c) => {
      if (amountRegex.test(c.totalAmount.toString())) {
        finalAmount += Number(c.totalAmount);
      }
    });
    bill.totalAmount = finalAmount.toString();

    setInputs((p) => ({
      ...p,
      [type]: {
        ...p[type],
        value: bill,
      },
    }));
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    let hadErrors = false;
    const setErrorMessage = (name, message) => {
      setInputs((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          error: true,
          errorMessage: message,
        },
      }));
      hadErrors = true;
    };
    const returnValue = (value) => {
      return typeof value === "string" ? value?.trim() : value;
    };
    let {
      ticket,
      type,
      deliveryType,
      serviceCenter,
      estimatedBill,
      bill,
      cancelledReasons,
    } = inputs;
    ticket = returnValue(ticket.value);
    type = returnValue(type.value);
    deliveryType = returnValue(deliveryType.value);
    cancelledReasons = returnValue(cancelledReasons.value);
    serviceCenter = returnValue(serviceCenter.value);
    estimatedBill = returnValue(estimatedBill.value);
    bill = returnValue(bill.value);

    if (!ticket) {
      setErrorMessage("ticket", errors.ticketRequired);
    }
    if (!type) {
      setErrorMessage("type", errors.typeRequired);
    }
    if (!deliveryType) {
      setErrorMessage("deliveryType", errors.deliveryTypeRequired);
    }

    if (service.status === "cancelled" && !cancelledReasons) {
      setErrorMessage("cancelledReasons", errors.cancelledReasonsRequired);
    }
    if (!serviceCenter) {
      setErrorMessage("serviceCenter", errors.serviceCenterRequired);
    }

    let estimatedBillError = false;
    let billError = false;

    if (estimatedBill && estimatedBill.items?.length > 0) {
      estimatedBill.items.forEach((e) => {
        let { quantity, amount } = e;
        quantity = quantity?.toString() || "";
        amount = amount?.toString() || "";
        if (quantity && !qntyRegex.test(quantity)) {
          e.error = "quantity";
          e.errorMessage = "Invalid Quantity format";
          estimatedBillError = true;
        }
        if (amount && !amountRegex.test(amount)) {
          e.error = "amount";
          e.errorMessage = "Invalid amount format";
          estimatedBillError = true;
        }
      });
    }

    if (bill && bill.items?.length > 0) {
      bill.items.forEach((e) => {
        let { quantity, amount } = e;
        quantity = quantity?.toString() || "";
        amount = amount?.toString() || "";
        if (quantity && !qntyRegex.test(quantity)) {
          e.error = "quantity";
          e.errorMessage = "Invalid Quantity format";
          estimatedBillError = true;
        }
        if (amount && !amountRegex.test(amount)) {
          e.error = "amount";
          e.errorMessage = "Invalid amount format";
          billError = true;
        }
      });
    }

    if (estimatedBillError || billError) {
      hadErrors = true;
      setInputs((p) => ({
        ...p,
        estimatedBill: {
          ...p.estimatedBill,
          value: estimatedBill,
        },
        bill: {
          ...p.bill,
          value: bill,
        },
      }));
    }

    if (hadErrors) {
      return;
    }
    let data = {
      ticket: ticket,
      type: type,
      deliveryType: deliveryType.value,
      serviceCenter: serviceCenter._id,
    };

    if (service.status === "cancelled") {
      data.cancelledReasons = cancelledReasons;
    }

    if (estimatedBill) {
      estimatedBill.items.forEach((e) => {
        let { quantity, amount, totalAmount } = e;
        quantity = quantity?.toString();
        amount = amount?.toString();
        totalAmount = totalAmount?.toString();
        if (quantity && qntyRegex.test(quantity)) {
          e.quantity = Number(quantity);
        }
        if (amount && amountRegex.test(amount)) {
          e.amount = Number(amount);
        }
        if (totalAmount && amountRegex.test(totalAmount)) {
          e.totalAmount = Number(totalAmount);
        }
      });
      estimatedBill.totalAmount = Number(estimatedBill.totalAmount);
      data.estimatedBill = estimatedBill;
    }

    if (bill) {
      bill.items.forEach((e) => {
        let { quantity, amount, totalAmount } = e;
        quantity = quantity?.toString();
        amount = amount?.toString();
        totalAmount = totalAmount?.toString();
        if (quantity && qntyRegex.test(quantity)) {
          e.quantity = Number(quantity);
        }
        if (amount && amountRegex.test(amount)) {
          e.amount = Number(amount);
        }
        if (totalAmount && amountRegex.test(totalAmount)) {
          e.totalAmount = Number(totalAmount);
        }
      });
      bill.totalAmount = Number(bill.totalAmount);
      data.bill = bill;
    }

    if (mode === "edit") {
      onUpdateService(inputs.id.value, data, (result) => {
        navigate("/dashboard/services");
      });
    }
  };

  const getServiceCenters = () => {
    onGetServiceCenters(
      (result) => {
        let serviceCenters = result.serviceCenters;
        setServiceCenters(serviceCenters);
      },
      false,
      false
    );
  };

  const onDeleteBill = (type, index) => {
    let bill = inputs[type].value;
    let finalItems = bill.items.filter((c, i) => i !== index);
    if (finalItems.length === 0) {
      return;
    }
    bill.items = finalItems;
    let finalAmount = 0;
    finalItems.forEach((f) => {
      if (amountRegex.test(f.totalAmount.toString())) {
        finalAmount += Number(f.totalAmount);
      }
    });
    bill.totalAmount = finalAmount.toString();
    setInputs((p) => ({
      ...p,
      [type]: {
        ...p[type],
        value: bill,
      },
    }));
  };

  const onAddBill = (type) => {
    let bill = inputs[type].value;
    let currentItems = bill.items || [];
    currentItems.push({
      name: "",
      amount: "",
      totalAmount: "",
      quantity: "",
      description: "",
    });
    bill.items = currentItems;

    setInputs((p) => ({
      ...p,
      [type]: {
        ...p.estimatedBill,
        value: bill,
      },
    }));
  };

  const getFormattedAddress = async (location) => {
    let { latitude: lat, longitude: lng } = location;
    let response = await fromLatLng(lat, lng);
    const { formatted_address } = response?.results[0];
    setFormattedLocation(formatted_address);
  };

  return (
    <section>
      {service && (
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Edit Service
            </Typography>
            <br />
            <Button
              fullWidth
              style={{
                backgroundColor: getServiceStatusColor(service.status),
                color: "#fff",
              }}
            >
              {" "}
              {service.status === "waiting"
                ? "WAITING FOR APPROVAL"
                : service.status === "payment"
                ? "WAITING FOR DELIVERY"
                : _.upperCase(service.status)}
            </Button>
            <div
              style={{
                textAlign: "center",
              }}
            >
              {service.status === "cancelled" && (
                <p
                  style={{
                    color: getServiceStatusColor(service.status),
                  }}
                >
                  {" "}
                  Reasons : {service.cancelledReasons}
                </p>
              )}
              {service.status === "delivered" && service.deliveredOn && (
                <p
                  style={{
                    color: getServiceStatusColor(service.status),
                  }}
                >
                  {" "}
                  Delivered On :{" "}
                  {moment(service.deliveredOn).format("MMM DD, YYYY")} at{" "}
                  {moment(service.deliveredOn).format("hh:mm:ss A")}
                </p>
              )}
            </div>

            <Box
              component="form"
              noValidate
              onSubmit={onSubmitForm.bind(this)}
              sx={{ mt: 4 }}
            >
              <Grid container spacing={2}>
                {/* for form */}

                {/* ticket */}
                <Grid item md={6}>
                  <TextField
                    error={inputs.ticket.error}
                    helperText={inputs.ticket.errorMessage}
                    margin="normal"
                    placeholder="Enter Ticket"
                    required
                    fullWidth
                    id="ticket"
                    label="Ticket"
                    name="ticket"
                    value={inputs.ticket.value}
                    onChange={onValueChangeHandler}
                  />
                </Grid>

                {/* service type */}
                <Grid item md={6}>
                  <TextField
                    error={inputs.type.error}
                    helperText={inputs.type.errorMessage}
                    margin="normal"
                    placeholder="Enter Service Type"
                    required
                    fullWidth
                    id="type"
                    label="Service Type"
                    name="type"
                    value={inputs.type.value}
                    onChange={onValueChangeHandler}
                  />
                </Grid>

                {/* Delivery type */}
                <Grid item md={4}>
                  <Autocomplete
                    disablePortal
                    id="deliveryType"
                    className="mt-1"
                    options={deliveryTypes}
                    fullWidth
                    value={inputs.deliveryType.value || null}
                    onChange={(e, newValue) => {
                      onValueChangeHandler({
                        target: {
                          name: "deliveryType",
                          value: newValue,
                        },
                      });
                    }}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={inputs.deliveryType.error}
                        helperText={inputs.deliveryType.errorMessage}
                        label="Delivery Type"
                      />
                    )}
                  />
                </Grid>

                {/* Service Center */}
                <Grid item md={4}>
                  <Autocomplete
                    disablePortal
                    id="serviceCenter"
                    className="mt-1"
                    options={serviceCenters}
                    fullWidth
                    value={inputs.serviceCenter.value || null}
                    onChange={(e, newValue) => {
                      onValueChangeHandler({
                        target: {
                          name: "serviceCenter",
                          value: newValue,
                        },
                      });
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        error={inputs.serviceCenter.error}
                        helperText={inputs.serviceCenter.errorMessage}
                        label="Service Center"
                      />
                    )}
                  />
                </Grid>

                {/* user number */}
                <Grid item md={4}>
                  <TextField
                    margin="normal"
                    disabled
                    multiline
                    placeholder="Contact Number"
                    fullWidth
                    id="phone"
                    label="Customer Contact"
                    name="phone"
                    value={service?.user.phone}
                  />
                </Grid>

                {service?.status === "cancelled" && (
                  <Grid item md={12}>
                    <TextField
                      error={inputs.cancelledReasons.error}
                      helperText={inputs.cancelledReasons.errorMessage}
                      margin="normal"
                      placeholder="Enter Cancellation results"
                      required
                      fullWidth
                      id="type"
                      label="Cancelled Reasons"
                      name="cancelledReasons"
                      value={inputs.cancelledReasons.value}
                      onChange={onValueChangeHandler}
                    />
                  </Grid>
                )}

                {/* description */}
                {service?.description && (
                  <Grid item md={12}>
                    <TextField
                      margin="normal"
                      disabled
                      multiline
                      placeholder="Description or notes here"
                      fullWidth
                      id="description"
                      label="Description or Notes"
                      name="description"
                      value={service?.description}
                    />
                  </Grid>
                )}

                {/* location */}
                {formattedLocation && (
                  <Grid item md={12}>
                    <TextField
                      margin="normal"
                      disabled
                      multiline
                      placeholder="User Location"
                      fullWidth
                      id="location"
                      label="Customer / User Location"
                      name="location"
                      value={formattedLocation}
                    />
                  </Grid>
                )}

                {service?.images && service.images.length > 0 && (
                  <Grid item md={12}>
                    <div className={classes.serviceImages}>
                      {service.images.map((image, i) => {
                        return (
                          <img
                            onClick={() => window.open(image, "__blank")}
                            className={classes.serviceImage}
                            src={image}
                            alt="..."
                            key={i}
                          />
                        );
                      })}
                    </div>
                  </Grid>
                )}

                {/* vehicle */}
                <Grid item md={12}>
                  <br />
                  <h3>Vehicle Details</h3>
                  <br />
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Type</TableCell>
                          <TableCell>Brand</TableCell>
                          <TableCell>Model</TableCell>
                          <TableCell>Number</TableCell>
                          <TableCell>Image </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell>
                            <div className={classes.vehicleType}>
                              {service?.vehicle?.type === "car" ? (
                                <AiFillCar color="#fff" size={25} />
                              ) : (
                                <FaMotorcycle color="#fff" size={25} />
                              )}
                            </div>
                          </TableCell>
                          <TableCell>{service?.vehicle.brand}</TableCell>
                          <TableCell>{service?.vehicle.model}</TableCell>
                          <TableCell>{service?.vehicle.number}</TableCell>
                          <TableCell>
                            {service?.vehicle.image && (
                              <img
                                onClick={() =>
                                  window.open(service?.vehicle.image, "__blank")
                                }
                                className={classes.vehicleImage}
                                src={service?.vehicle.image}
                                alt="..."
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                {/* payment */}
                {service?.payment && (
                  <Grid item md={12}>
                    <br />
                    <h3>Payment </h3>
                    <br />
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                          <TableRow>
                            <TableCell>Payment Id </TableCell>
                            <TableCell>
                              <strong>{service.payment.payment_id}</strong>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Order Id </TableCell>
                            <TableCell>
                              <strong>{service.payment.order_id}</strong>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Payment Mode </TableCell>
                            <TableCell>
                              {_.toUpper(service.payment.paymentType)}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Method </TableCell>
                            <TableCell>
                              {_.toUpper(service.payment.method)}
                            </TableCell>
                          </TableRow>

                          {service.payment.bank && (
                            <TableRow>
                              <TableCell>Bank </TableCell>
                              <TableCell>{service.payment.bank}</TableCell>
                            </TableRow>
                          )}

                          {service.payment.vpa && (
                            <TableRow>
                              <TableCell>UPI </TableCell>
                              <TableCell>{service.payment.vpa}</TableCell>
                            </TableRow>
                          )}

                          {service.payment.wallet && (
                            <TableRow>
                              <TableCell>Wallet </TableCell>
                              <TableCell>{service.payment.wallet}</TableCell>
                            </TableRow>
                          )}

                          {service?.payment.couponCode && (
                            <TableRow>
                              <TableCell>Coupon Code Used</TableCell>
                              <TableCell>
                                <strong>
                                  {service.payment.couponCode.code}
                                </strong>
                                <br />
                                {service.payment.couponCode.description}
                              </TableCell>
                            </TableRow>
                          )}

                          <TableRow>
                            <TableCell>Total Bill </TableCell>
                            <TableCell>
                              {" "}
                              {getCurrencyLocaleString(
                                service.payment.totalAmount
                              )}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Discount</TableCell>
                            <TableCell
                              style={{
                                color: "green",
                              }}
                            >
                              -{" "}
                              {getCurrencyLocaleString(
                                service.payment.discount
                              )}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              <strong>Paid</strong>
                            </TableCell>
                            <TableCell>
                              <strong>
                                {getCurrencyLocaleString(
                                  service.payment.amount / 100
                                )}
                              </strong>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}

                {/* bill */}

                {inputs.bill && inputs.bill.value && (
                  <Grid item md={12}>
                    <br />
                    <h3>Generated Bill </h3>
                    <br />
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Desription</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Total Amount </TableCell>
                            <TableCell>Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {inputs.bill.value.items.map((item, index) => {
                            let {
                              name,
                              description,
                              quantity,
                              amount,
                              totalAmount,
                              error,
                              errorMessage,
                            } = item;
                            return (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  <TextField
                                    margin="normal"
                                    variant="standard"
                                    error={error === "name"}
                                    helperText={
                                      error === "name" && errorMessage
                                    }
                                    placeholder="Ex : Clutch wires"
                                    required
                                    fullWidth
                                    id="name"
                                    name="name"
                                    value={name}
                                    onChange={(e) =>
                                      onBillValueChangeHandler(
                                        "bill",
                                        "name",
                                        index,
                                        e.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <TextField
                                    margin="normal"
                                    variant="standard"
                                    placeholder="Ex: Changed engine oil"
                                    fullWidth
                                    id="description"
                                    name="description"
                                    value={description || ""}
                                    onChange={(e) =>
                                      onBillValueChangeHandler(
                                        "bill",
                                        "description",
                                        index,
                                        e.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <TextField
                                    margin="normal"
                                    variant="standard"
                                    placeholder="Enter Quantity"
                                    fullWidth
                                    error={error === "quantity"}
                                    helperText={
                                      error === "quantity" && errorMessage
                                    }
                                    id="quantity"
                                    name="quantity"
                                    value={quantity || ""}
                                    onChange={(e) =>
                                      onBillValueChangeHandler(
                                        "bill",
                                        "quantity",
                                        index,
                                        e.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <TextField
                                    margin="normal"
                                    variant="standard"
                                    placeholder="Amount"
                                    error={error === "amount"}
                                    helperText={
                                      error === "amount" && errorMessage
                                    }
                                    fullWidth
                                    id="amount"
                                    name="amount"
                                    value={amount}
                                    onChange={(e) =>
                                      onBillValueChangeHandler(
                                        "bill",
                                        "amount",
                                        index,
                                        e.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {getCurrencyLocaleString(totalAmount)}
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    onClick={() => onDeleteBill("bill", index)}
                                    aria-label="delete"
                                    color="error"
                                  >
                                    <DeleteIcon />
                                  </IconButton>{" "}
                                </TableCell>
                              </TableRow>
                            );
                          })}

                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell>
                              <strong>
                                {getCurrencyLocaleString(
                                  inputs.bill.value.totalAmount
                                )}
                              </strong>
                            </TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => onAddBill("bill")}
                                aria-label="delete"
                                color="info"
                              >
                                <AddIcon />
                              </IconButton>{" "}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}

                {/* estimated bill */}

                {inputs.estimatedBill && inputs.estimatedBill.value && (
                  <Grid item md={12}>
                    <br />
                    <h3>Estimated Bill </h3>
                    <br />
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Desription</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Total Amount </TableCell>
                            <TableCell>Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {inputs.estimatedBill.value.items.map(
                            (bill, index) => {
                              let {
                                name,
                                description,
                                quantity,
                                amount,
                                totalAmount,
                                error,
                                errorMessage,
                              } = bill;
                              return (
                                <TableRow
                                  key={index}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    <TextField
                                      margin="normal"
                                      variant="standard"
                                      error={error === "name"}
                                      helperText={
                                        error === "name" && errorMessage
                                      }
                                      placeholder="Ex : Clutch wires"
                                      required
                                      fullWidth
                                      id="name"
                                      name="name"
                                      value={name}
                                      onChange={(e) =>
                                        onBillValueChangeHandler(
                                          "estimatedBill",
                                          "name",
                                          index,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <TextField
                                      margin="normal"
                                      variant="standard"
                                      placeholder="Ex: Changed engine oil"
                                      fullWidth
                                      id="description"
                                      name="description"
                                      value={description || ""}
                                      onChange={(e) =>
                                        onBillValueChangeHandler(
                                          "estimatedBill",
                                          "description",
                                          index,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <TextField
                                      margin="normal"
                                      variant="standard"
                                      placeholder="Enter Quantity"
                                      fullWidth
                                      error={error === "quantity"}
                                      helperText={
                                        error === "quantity" && errorMessage
                                      }
                                      id="quantity"
                                      name="quantity"
                                      value={quantity || ""}
                                      onChange={(e) =>
                                        onBillValueChangeHandler(
                                          "estimatedBill",
                                          "quantity",
                                          index,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <TextField
                                      margin="normal"
                                      variant="standard"
                                      placeholder="Amount"
                                      error={error === "amount"}
                                      helperText={
                                        error === "amount" && errorMessage
                                      }
                                      fullWidth
                                      id="amount"
                                      name="amount"
                                      value={amount}
                                      onChange={(e) =>
                                        onBillValueChangeHandler(
                                          "estimatedBill",
                                          "amount",
                                          index,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {getCurrencyLocaleString(totalAmount)}
                                  </TableCell>
                                  <TableCell>
                                    <IconButton
                                      onClick={() =>
                                        onDeleteBill("estimatedBill", index)
                                      }
                                      aria-label="delete"
                                      color="error"
                                    >
                                      <DeleteIcon />
                                    </IconButton>{" "}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}

                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell>
                              <strong>
                                {getCurrencyLocaleString(
                                  inputs.estimatedBill.value.totalAmount
                                )}
                              </strong>
                            </TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => onAddBill("estimatedBill")}
                                aria-label="delete"
                                color="info"
                              >
                                <AddIcon />
                              </IconButton>{" "}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}

                {/* submit button */}

                {service && service.status !== "delivered" && (
                  <LoadingButton
                    type="submit"
                    fullWidth
                    loadingPosition="end"
                    endIcon={<FaWrench />}
                    color="primary"
                    loading={loading}
                    loadingIndicator={"Adding..."}
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {!loading && mode === "edit"
                      ? "PROCEED & UPDATE"
                      : "PROCEED & ADD"}
                  </LoadingButton>
                )}
              </Grid>
            </Box>
          </CardContent>
        </Card>
      )}
    </section>
  );
};

export default CreateEditService;
