import "./App.css";
import Loader from "./shared/Loader/Loader";
import { Notification } from "./shared/Notification/Notification";
import Layout from "./layout/Layout";
import { ThemeProvider, createTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const theme = createTheme({
  palette: {
    primary: {
      main: "#EA6A47",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Notification />
        <Layout />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
