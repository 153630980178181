/* eslint-disable react-hooks/exhaustive-deps */
import { createContext } from "react";

import useHttp from "../../hooks/useHttp";

export const ServiceCentersContext = createContext({
  onCreateServiceCenter: (data, callback, errorCallBack, loader, notify) =>
    null,
  onUpdateServiceCenter: (
    serviceCenterId,
    data,
    callback,
    errorCallBack,
    loader,
    notify
  ) => null,
  onGetServiceCenters: (callback, loader, notify) => null,
  onGetServiceCenter: (
    serviceCenterId,
    callback,
    errorCallBack,
    loader,
    notify
  ) => null,
  onDeleteServiceCenter: (
    serviceCenterId,
    callback,
    errorCallBack,
    loader,
    notify
  ) => null,
});

export const ServiceCentersContextProvider = ({ children }) => {
  const { sendRequest } = useHttp();

  const baseUrl = "/admin/";

  const onCreateServiceCenter = async (
    data,
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + `service-center/`,
        type: "POST",
        data: data,
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  const onUpdateServiceCenter = async (
    serviceCenterId,
    data,
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + `service-center/` + serviceCenterId,
        type: "PUT",
        data: data,
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  const onGetServiceCenters = async (
    callback = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + "service-center",
      },
      {
        successCallback: callback,
      },
      loader,
      notify
    );
  };

  const onGetServiceCenter = async (
    serviceCenterId,
    callback = () => null,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + "service-center/" + serviceCenterId,
      },
      {
        successCallback: callback,
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  const onDeleteServiceCenter = async (
    serviceCenterId,
    callback = () => null,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + "service-center/" + serviceCenterId,
        type: "DELETE",
      },
      {
        successCallback: callback,
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  return (
    <ServiceCentersContext.Provider
      value={{
        onCreateServiceCenter,
        onUpdateServiceCenter,
        onGetServiceCenter,
        onGetServiceCenters,
        onDeleteServiceCenter,
      }}
    >
      {children}
    </ServiceCentersContext.Provider>
  );
};
