import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import { CategoriesAndProductsContext } from "../../../../services/CategoriesAndProducts/CategoriesAndProducts.context";
import { EditorState } from "draft-js";
import { convertFromHTML, convertToHTML } from "draft-convert";
import { Editor } from "react-draft-wysiwyg";
import { AiFillFileImage } from "react-icons/ai";
import { convertFilesToBase64 } from "../../../../utility/helper";
import _ from "lodash";
import classes from "./Products.module.css";
import { showNotification } from "../../../../shared/Notification/Notification";
import Swal from "sweetalert2";

const errors = {
  nameRequired: "Product Name Required",
  descriptionRequired: "Description required",
  categoryRequired: "Please select category",
  quantityRequired: "Please select quantity",
  amountRequired: "Enter the price of the product",
  codChargesRequired: "Please enter Cash On Delivery Charges",
  deliveryChargesRequired: "Please enter  Delivery Charges",
};

const commonInputFieldProps = {
  value: "",
  focused: false,
  error: false,
  errorMessage: "",
};

const defaultInputState = {
  name: {
    ...commonInputFieldProps,
  },
  description: {
    ...commonInputFieldProps,
  },
  discount: {
    ...commonInputFieldProps,
  },
  amount: {
    ...commonInputFieldProps,
  },
  quantity: {
    ...commonInputFieldProps,
  },
  finalAmount: {
    ...commonInputFieldProps,
  },
  category: {
    ...commonInputFieldProps,
  },
  codAvailable: {
    ...commonInputFieldProps,
    value: true,
  },
  codCharges: {
    ...commonInputFieldProps,
  },
  deliveryCharges: {
    ...commonInputFieldProps,
  },
  id: {
    ...commonInputFieldProps,
  },
};

const CreateEditProduct = ({ mode }) => {
  const [product, setProduct] = useState(null);
  const [inputs, setInputs] = useState(defaultInputState);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    onCreateProduct,
    onGetCategories,
    onGetProduct,
    onUpdateProduct,
    onDeleteProductImage,
  } = useContext(CategoriesAndProductsContext);
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  let amountRegex = /^[0-9]+(\.[0-9]+)?$/;

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setInputs((p) => ({
      ...p,
      description: {
        error: false,
        errorMessage: "",
        value: html,
      },
    }));
  }, [editorState]);

  useEffect(() => {
    getCategories();
    if (mode) {
      let title = mode === "edit" ? "Edit Product" : "Add Product";
      document.title = title;
    }
    if (mode === "edit") {
      let editId = searchParams.get("id");
      if (!editId) {
        navigate("/dashboard/products");
        return;
      }
      if (editId) {
        onGetProduct(
          editId,
          (result) => {
            let productData = result.product;
            setProduct(productData);

            if (productData) {
              let {
                name,
                description,
                amount,
                finalAmount,
                discount,
                quantity,
                category,
                codAvailable,
                codCharges,
                deliveryCharges,
                _id,
              } = productData;
              let newEditorState = EditorState.createWithContent(
                convertFromHTML(description)
              );
              setEditorState(newEditorState);

              setInputs((prevState) => ({
                ...prevState,
                name: {
                  ...commonInputFieldProps,
                  value: name,
                },

                category: {
                  ...commonInputFieldProps,
                  value: category,
                },
                codAvailable: {
                  ...commonInputFieldProps,
                  value: codAvailable,
                },
                codCharges: {
                  ...commonInputFieldProps,
                  value: codCharges?.toString() || "",
                },
                deliveryCharges: {
                  ...commonInputFieldProps,
                  value: deliveryCharges?.toString() || "",
                },
                amount: {
                  ...commonInputFieldProps,
                  value: amount.toString(),
                },
                quantity: {
                  ...commonInputFieldProps,
                  value: quantity.toString(),
                },
                discount: {
                  ...commonInputFieldProps,
                  value: discount?.toString() || "",
                },
                finalAmount: {
                  ...commonInputFieldProps,
                  value: finalAmount?.toString(),
                },
                id: {
                  value: _id,
                },
              }));
            } else {
              navigate("/dashboard/products");
            }
          },
          () => {
            navigate("/dashboard/products");
          },
          false,
          false
        );
      }
    }
  }, [mode]);

  const getCategories = () => {
    onGetCategories(
      (result) => {
        let categories = result.allCategories;
        setCategories(categories);
      },
      false,
      false
    );
  };

  const onValueChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        error: false,
        errorMessage: "",
        value,
      },
    }));
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    let hadErrors = false;
    const setErrorMessage = (name, message) => {
      setInputs((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          error: true,
          errorMessage: message,
        },
      }));
      hadErrors = true;
    };
    const returnValue = (value) => {
      return typeof value === "string" ? value?.trim() : value;
    };
    let {
      name,
      description,
      category,
      discount,
      amount,
      finalAmount,
      codAvailable,
      codCharges,
      deliveryCharges,
      quantity,
    } = inputs;
    name = returnValue(name.value);
    description = returnValue(description.value);
    category = returnValue(category.value);
    quantity = returnValue(quantity.value);
    discount = returnValue(discount.value);
    amount = returnValue(amount.value);
    finalAmount = returnValue(finalAmount.value);
    codAvailable = returnValue(codAvailable.value);
    codCharges = returnValue(codCharges.value);
    deliveryCharges = returnValue(deliveryCharges.value);

    let zeroRegex = /^[0-9]\d*$/;

    if (!name) {
      setErrorMessage("name", errors.nameRequired);
    }

    if (!description || description === "<p></p>") {
      setErrorMessage("description", errors.descriptionRequired);
    }

    if (!category) {
      setErrorMessage("category", errors.categoryRequired);
    }

    if (!zeroRegex.test(quantity)) {
      setErrorMessage("quantity", errors.quantityRequired);
    }

    if (!amountRegex.test(amount)) {
      setErrorMessage("amount", errors.amountRequired);
    }

    if (!amountRegex.test(finalAmount)) {
      setErrorMessage("finalAmount", "Final Pice Required");
    }

    if (codAvailable && !amountRegex.test(codCharges)) {
      setErrorMessage("codCharges", "Invalid COD Charges format");
    }

    if (discount && !zeroRegex.test(discount)) {
      setErrorMessage("discount", "Invalid Discount Format");
    }

    if (deliveryCharges && !amountRegex.test(deliveryCharges)) {
      setErrorMessage("deliveryCharges", "Invalid Delivery Charges format");
    }

    if (mode !== "edit" && (!images || images.length === 0)) {
      showNotification({
        message: "Upload atleast one image",
        status: "error",
      });
      hadErrors = true;
    }

    if (hadErrors) {
      return;
    }
    let data = {
      name: name,
      description: description,
      amount: Number(amount),
      quantity: Number(quantity),
      finalAmount: Number(finalAmount),
      category: category._id,
      codAvailable: codAvailable,
      images: images,
    };
    if (discount) {
      data.discount = Number(discount);
    }
    if (codAvailable) {
      data.codCharges = Number(codCharges);
    }
    if (deliveryCharges) {
      data.deliveryCharges = Number(deliveryCharges);
    }

    if (mode === "edit") {
      onUpdateProduct(inputs.id.value, data, () => {
        navigate("/dashboard/products");
      });
    } else {
      onCreateProduct(data, () => {
        navigate("/dashboard/products");
      });
    }
  };

  useEffect(() => {
    let amount = inputs.amount.value;
    let discount = inputs.discount.value;
    if (amount) {
      amount = Number(amount);
      discount = Number(discount);
      let amountValid = amountRegex.test(amount);
      if (amountValid) {
        if (discount && /^[0-9]+$/.test(discount)) {
          let discountApplied = amount * (discount / 100);
          let finalAmount = amount - discountApplied;
          setInputs((p) => ({
            ...p,
            finalAmount: {
              ...p.finalAmount,
              error: false,
              errorMessage: "",
              value: finalAmount.toString(),
            },
          }));
        } else {
          setInputs((p) => ({
            ...p,
            finalAmount: {
              ...p.finalAmount,
              error: false,
              errorMessage: "",
              value: amount.toString(),
            },
          }));
        }
      }
    }
  }, [inputs.amount.value, inputs.discount.value]);

  const handleFileSelect = async (event) => {
    const files = event.target.files;
    try {
      const base64Urls = await convertFilesToBase64(files);
      setImages(base64Urls);
    } catch (error) {
      console.error("Error converting file to base64:", error);
    }
  };

  const onRemovePreviousImage = (image) => {
    Swal.fire({
      title: "Are you sure to remove the image?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          image: image,
        };
        onDeleteProductImage(inputs.id.value, data, (result) => {
          setProduct(result.product);
        });
      }
    });
  };

  const onRemoveImage = (image) => {
    let currentImages = _.cloneDeep(images);
    let finalImages = currentImages.filter((i, index) => i !== image);
    setImages(finalImages);
  };

  return (
    <section>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {mode === "create" ? "Add Product" : "Edit Product"}
          </Typography>
          <br />
          <Box
            component="form"
            noValidate
            onSubmit={onSubmitForm.bind(this)}
            sx={{ mt: 2 }}
          >
            <Grid container spacing={2}>
              {/* for form */}

              {/* name */}
              <Grid item md={6}>
                <TextField
                  error={inputs.name.error}
                  helperText={inputs.name.errorMessage}
                  margin="normal"
                  placeholder="Enter the product name "
                  required
                  fullWidth
                  id="name"
                  label="Product Name"
                  name="name"
                  value={inputs.name.value}
                  onChange={onValueChangeHandler}
                />
              </Grid>

              {/* category */}
              <Grid item md={6}>
                <Autocomplete
                  disablePortal
                  id="category"
                  className="mt-1"
                  options={categories}
                  fullWidth
                  value={inputs.category.value || null}
                  onChange={(e, newValue) => {
                    onValueChangeHandler({
                      target: {
                        name: "category",
                        value: newValue,
                      },
                    });
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={inputs.category.error}
                      helperText={inputs.category.errorMessage}
                      label="Select Category"
                    />
                  )}
                />
              </Grid>

              {/* description */}
              <Grid item md={12}>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={setEditorState}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                />
                {inputs.description.error && (
                  <FormHelperText error>
                    {inputs.description.errorMessage}
                  </FormHelperText>
                )}
              </Grid>

              {/* quantity */}
              <Grid item md={3}>
                <TextField
                  error={inputs.quantity.error}
                  helperText={inputs.quantity.errorMessage}
                  margin="normal"
                  placeholder="Enter the stock available "
                  required
                  fullWidth
                  id="quantity"
                  label="Quantity"
                  name="quantity"
                  value={inputs.quantity.value}
                  onChange={onValueChangeHandler}
                />
              </Grid>

              {/* Amount */}
              <Grid item md={3}>
                <TextField
                  error={inputs.amount.error}
                  helperText={inputs.amount.errorMessage}
                  margin="normal"
                  placeholder="Enter the product price "
                  required
                  fullWidth
                  id="amount"
                  label="Price"
                  name="amount"
                  value={inputs.amount.value}
                  onChange={onValueChangeHandler}
                />
              </Grid>

              {/* discount */}
              <Grid item md={3}>
                <TextField
                  error={inputs.discount.error}
                  helperText={inputs.discount.errorMessage}
                  margin="normal"
                  placeholder="Enter discount in percentage "
                  fullWidth
                  id="discount"
                  label="Discount % "
                  name="discount"
                  value={inputs.discount.value}
                  onChange={onValueChangeHandler}
                />
              </Grid>

              {/* Final Amount */}
              <Grid item md={3}>
                <TextField
                  error={inputs.finalAmount.error}
                  helperText={inputs.finalAmount.errorMessage}
                  margin="normal"
                  placeholder="Enter Product Final Price "
                  fullWidth
                  id="finalAmount"
                  label="Final Price"
                  name="finalAmount"
                  disabled
                  value={inputs.finalAmount.value}
                />
              </Grid>

              {/* cod available */}
              <Grid item md={4} className="mt-1">
                <FormControlLabel
                  onChange={(e) =>
                    onValueChangeHandler({
                      target: {
                        name: "codAvailable",
                        value: e.target.checked,
                      },
                    })
                  }
                  control={<Checkbox checked={inputs.codAvailable.value} />}
                  label="COD Available"
                />
              </Grid>

              {/* cod charges */}
              {inputs.codAvailable.value && (
                <Grid item md={4}>
                  <TextField
                    error={inputs.codCharges.error}
                    helperText={inputs.codCharges.errorMessage}
                    margin="normal"
                    placeholder="Enter Cod Charges "
                    fullWidth
                    id="codCharges"
                    label="COD Charges"
                    name="codCharges"
                    value={inputs.codCharges.value}
                    onChange={onValueChangeHandler}
                  />
                </Grid>
              )}

              {/* delivery charges */}
              <Grid item md={4}>
                <TextField
                  error={inputs.deliveryCharges.error}
                  helperText={inputs.deliveryCharges.errorMessage}
                  margin="normal"
                  placeholder="Enter Delivery Charges "
                  fullWidth
                  id="deliveryCharges"
                  label="Delivery Charges"
                  name="deliveryCharges"
                  value={inputs.deliveryCharges.value}
                  onChange={onValueChangeHandler}
                />
              </Grid>

              {/* images */}
              <Grid item md={12}>
                <Button
                  fullWidth
                  variant="contained"
                  className="mt-1"
                  component="label"
                  endIcon={<AiFillFileImage />}
                >
                  Add Images
                  <input
                    type="file"
                    multiple
                    onChange={handleFileSelect}
                    hidden
                    accept="image/*"
                  />
                </Button>
              </Grid>

              <Grid item md={12}>
                <div className={classes.images}>
                  {images &&
                    images.map((image, i) => {
                      return (
                        <div key={i} className={classes.imageContainer}>
                          <img
                            src={image}
                            className={classes.image}
                            alt="..."
                          />
                          <Button
                            variant="outlined"
                            color="error"
                            className={classes.deleteButton}
                            onClick={() => onRemoveImage(image)}
                          >
                            Delete
                          </Button>
                        </div>
                      );
                    })}
                </div>
              </Grid>

              {/* previous images*/}
              <Grid item md={12}>
                <div className={classes.images}>
                  {product?.images &&
                    product?.images.map((image, i) => {
                      return (
                        <div key={i} className={classes.imageContainer}>
                          <img
                            src={image}
                            className={classes.image}
                            alt="..."
                          />
                          <Button
                            variant="outlined"
                            color="error"
                            className={classes.deleteButton}
                            onClick={() => onRemovePreviousImage(image)}
                          >
                            Remove Image
                          </Button>
                        </div>
                      );
                    })}
                </div>
              </Grid>

              {/* submit button */}

              <LoadingButton
                type="submit"
                fullWidth
                loadingPosition="end"
                endIcon={<GiTicket />}
                color="primary"
                loading={loading}
                loadingIndicator={"Adding..."}
                variant="contained"
                sx={{ mt: 5, mb: 2 }}
              >
                {!loading && mode === "edit"
                  ? "PROCEED & UPDATE"
                  : "PROCEED & ADD"}
              </LoadingButton>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </section>
  );
};

export default CreateEditProduct;
