import { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../../../services/Dashboard/Dashboard.context";
import classes from "./MainDashboard.module.css";
import {
  FaScrewdriverWrench,
  FaBagShopping,
  FaProductHunt,
  FaWallet,
} from "react-icons/fa6";
import NoDataImg from "../../../../assets/no_data.png";
import {
  NotFoundContainer,
  NotFoundContainerImage,
  NotFoundText,
} from "../../../../styles";
import {
  getCurrencyLocaleString,
  getServiceStatusColor,
} from "../../../../utility/helper";
import { Bar, Line, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
} from "chart.js";
import hexToRgba from "hex-to-rgba";
import { Autocomplete, Card, CardContent, TextField } from "@mui/material";
import moment from "moment";

ChartJS.register(
  ArcElement,
  Tooltip,
  Title,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement
);

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  },
};

function getRandomColor() {
  // Generate random values for Red, Green, and Blue
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);

  // Convert RGB values to a hexadecimal color code
  const hexColor = `#${red.toString(16)}${green.toString(16)}${blue.toString(
    16
  )}`;

  return hexColor;
}

const MainDashboard = () => {
  const [data, setData] = useState(null);
  const { onGetDashboardData } = useContext(DashboardContext);
  const [servicesPieData, setServicesPieData] = useState(null);
  const [incomeChartData, setIncomeChartData] = useState(null);
  const [productSalesChartData, setProductSalesChartData] = useState(null);
  const [
    servicesByServiceCenterChartData,
    setServicesByServiceCenterChartData,
  ] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedProductSales, setSelectedProductSales] = useState(null);

  useEffect(() => {
    getDashboardData();
  }, []);

  useEffect(() => {
    if (selectedProductSales) {
      onSetProductSalesChart();
    }
  }, [selectedProductSales]);

  const getDashboardData = () => {
    onGetDashboardData(
      (result) => {
        console.log(result.data);
        setData(result.data);
        onSetChartData(result.data);
      },
      true,
      false
    );
  };

  const onSetChartData = (data) => {
    let { productSales, payments, services } = data;
    let {
      totalIncomeServicesCash,
      totalIncomeServicesOnline,
      totalIncomeProductsCash,
      totalIncomeProductsOnline,
      totalIncomeServices,
      totalIncomeProducts,
    } = payments;
    const { scheduled, progress, completed, payment, delivered, cancelled } =
      services.servicesData[0];
    let servicesByServiceCenter = services.servicesByServiceCenter;
    let structuredProducts = [];
    Object.keys(productSales).map((key) => structuredProducts.push(key));
    setProducts(structuredProducts);
    setSelectedProductSales(structuredProducts[0]);
    const servicesPie = {
      labels: [
        "Scheduled",
        "Progress",
        "Completed",
        "Waiting For Payment",
        "Delivered",
        "Cancelled",
      ],
      datasets: [
        {
          label: "Total ",
          data: [scheduled, progress, completed, payment, delivered, cancelled],
          backgroundColor: [
            hexToRgba(getServiceStatusColor("scheduled"), 0.5),
            hexToRgba(getServiceStatusColor("progress"), 0.5),
            hexToRgba(getServiceStatusColor("completed"), 0.5),
            hexToRgba(getServiceStatusColor("payment"), 0.5),
            hexToRgba(getServiceStatusColor("delivered"), 0.5),
            hexToRgba(getServiceStatusColor("cancelled"), 0.5),
          ],
          borderColor: [
            hexToRgba(getServiceStatusColor("scheduled")),
            hexToRgba(getServiceStatusColor("progress")),
            hexToRgba(getServiceStatusColor("completed")),
            hexToRgba(getServiceStatusColor("payment")),
            hexToRgba(getServiceStatusColor("delivered")),
            hexToRgba(getServiceStatusColor("cancelled")),
          ],
          borderWidth: 1,
        },
      ],
    };
    setServicesPieData(servicesPie);

    if (servicesByServiceCenter.length > 0) {
      const servicesByServiceCenterData = {
        labels: servicesByServiceCenter.map((s) => s.name),
        datasets: [
          {
            label: "Total Services",
            data: servicesByServiceCenter.map((s) => s.totalServices),
            backgroundColor: servicesByServiceCenter.map((s) =>
              hexToRgba(getRandomColor(), 0.5)
            ),
          },
        ],
      };
      setServicesByServiceCenterChartData(servicesByServiceCenterData);
    }

    const incomeData = {
      labels: ["Product Payments", "Services Payments"],
      datasets: [
        {
          label: "( Cash + Online )",
          data: [totalIncomeProducts, totalIncomeServices],
          backgroundColor: [hexToRgba("#ea6a47", 0.8)],
        },
        {
          label: "Cash ",
          data: [totalIncomeProductsCash, totalIncomeServicesCash],
          backgroundColor: [hexToRgba("#5cb85c", 0.8)],
        },
        {
          label: "Online",
          data: [totalIncomeProductsOnline, totalIncomeServicesOnline],
          backgroundColor: [hexToRgba("#0275d8", 0.8)],
        },
      ],
    };
    setIncomeChartData(incomeData);
  };

  const onSetProductSalesChart = () => {
    let productData = data.productSales[selectedProductSales];
    if (productData.sales && productData.sales.length > 0) {
      const labels = [];
      let data = [];
      productData.sales.forEach((s) => {
        const date = moment(s.date, "DD-MM-YYYY");
        const dayOfWeek = date.format("ddd");
        labels.push(dayOfWeek + " (" + date.format("DD") + ")");
        data.push(s.quantity);
      });
      const chartData = {
        labels,
        datasets: [
          {
            label: "No of sales ",
            data: data,
            borderColor: hexToRgba("#ea6a47", 0.5),
            backgroundColor: hexToRgba("#ea6a47"),
          },
        ],
      };
      setProductSalesChartData(chartData);
    }
  };

  return (
    <div className={classes.main}>
      {!data && (
        <NotFoundContainer>
          <div>
            <NotFoundText>No Data Found</NotFoundText>
            <NotFoundContainerImage src={NoDataImg} alt="..." />
          </div>
        </NotFoundContainer>
      )}

      {data && (
        <>
          <div className={classes.cardContainer}>
            <div className={classes.card}>
              <div>
                <p className={classes.number}>{data.totalProducts || "0"}</p>
                <p className={classes.text}>Total Products</p>
              </div>
              <div className={classes.iconContainer}>
                <FaProductHunt size={30} className={classes.icon} />
              </div>
            </div>
            <div className={classes.card}>
              <div>
                <p className={classes.number}>
                  {data.services.servicesData[0]?.totalServices || "0"}
                </p>
                <p className={classes.text}>Total Services</p>
              </div>
              <div className={classes.iconContainer}>
                <FaScrewdriverWrench size={30} className={classes.icon} />
              </div>
            </div>
            <div className={classes.card}>
              <div>
                <p className={classes.number}>{data.totalOrders || "0"}</p>
                <p className={classes.text}>Total Orders</p>
              </div>
              <div className={classes.iconContainer}>
                <FaBagShopping size={30} className={classes.icon} />
              </div>
            </div>
            <div className={classes.card}>
              <div>
                <p className={classes.number}>
                  {getCurrencyLocaleString(data.payments.totalIncome || 0)}
                </p>
                <p className={classes.text}>Income</p>
              </div>
              <div className={classes.iconContainer}>
                <FaWallet size={30} className={classes.icon} />
              </div>
            </div>
          </div>

          <div className={classes.analyzeCards}>
            {servicesPieData && (
              <Card variant="outlined" className={classes.analyzeCard}>
                <CardContent>
                  <h3>Services Tracking</h3>
                </CardContent>
                <div className={classes.chart}>
                  <Pie
                    data={servicesPieData}
                    options={{
                      ...chartOptions,
                      scales: {
                        ...chartOptions.scales,
                        y: {
                          ...chartOptions.scales.y,
                          ticks: {
                            display: false,
                            beginAtZero: true,
                          },
                        },
                        x: {
                          ...chartOptions.scales.x,
                          ticks: {
                            display: false,
                            beginAtZero: true,
                          },
                        },
                      },
                    }}
                  />
                </div>
              </Card>
            )}

            {productSalesChartData && (
              <Card variant="outlined" className={classes.analyzeCard}>
                <CardContent>
                  <h3>Product Sales (Past 7 days)</h3>
                  <br />
                  <Autocomplete
                    disablePortal
                    id="product"
                    className="mt-1"
                    options={products || []}
                    fullWidth
                    value={selectedProductSales || null}
                    onChange={(e, newValue) => {
                      setSelectedProductSales(newValue);
                    }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Product" />
                    )}
                  />
                  <br />
                  <br />
                  <div className={classes.chart}>
                    <Line
                      options={{
                        ...chartOptions,

                        plugins: {
                          legend: {
                            display: false,
                          },
                        },
                      }}
                      data={productSalesChartData}
                    />
                  </div>
                </CardContent>
              </Card>
            )}

            {servicesByServiceCenterChartData && (
              <Card variant="outlined" className={classes.analyzeCard}>
                <CardContent>
                  <h3>Services By Service Center</h3>
                </CardContent>
                <div className={classes.chart}>
                  <Bar
                    options={{
                      ...chartOptions,
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                    data={servicesByServiceCenterChartData}
                  />
                </div>
              </Card>
            )}

            {incomeChartData && (
              <Card variant="outlined" className={classes.analyzeCard}>
                <CardContent>
                  <h3>
                    Payments Split Wise of ({" "}
                    {getCurrencyLocaleString(data.payments.totalIncome || 0)} )
                  </h3>
                </CardContent>
                <div className={classes.chart}>
                  <Bar
                    options={{
                      ...chartOptions,
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                    data={incomeChartData}
                  />
                </div>
              </Card>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MainDashboard;
