import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { AiFillFileImage, AiFillShop } from "react-icons/ai";
import countries from "../../../../utility/countries.json";
import { GoogleMap, Marker } from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { fromLatLng, setKey } from "react-geocode";
import { showNotification } from "../../../../shared/Notification/Notification";
import { convertFileToBase64 } from "../../../../utility/helper";
import { ServiceCentersContext } from "../../../../services/ServiceCenters/ServiceCenters.context";
import { useNavigate, useSearchParams } from "react-router-dom";

const errors = {
  imageRequired: "Image required & Only PNG, JPEG, JPG types allowed",
  nameRequired: "Please enter service center name",
  phoneRequired: "Invalid Mobile Number",
  countryCodeRequired: "Please select country code",
  addressRequired: "Please enter address",
  cityRequired: "Please enter city name",
  stateRequired: "Please enter state name",
};

const commonInputFieldProps = {
  value: "",
  focused: false,
  error: false,
  errorMessage: "",
};

const defaultInputState = {
  name: {
    ...commonInputFieldProps,
  },
  countryCode: {
    ...commonInputFieldProps,
    value: countries.find((c) => c.phone === "91"),
  },
  phone: {
    ...commonInputFieldProps,
  },
  description: {
    ...commonInputFieldProps,
  },
  state: {
    ...commonInputFieldProps,
  },
  city: {
    ...commonInputFieldProps,
  },
  address: {
    ...commonInputFieldProps,
  },
  image: {
    ...commonInputFieldProps,
  },
  visible: {
    ...commonInputFieldProps,
    value: true,
  },
  id: {
    ...commonInputFieldProps,
  },
};

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API;

const CreateEditServiceCenter = ({ mode }) => {
  const [map, setMap] = useState(null);
  const [serviceCenter, setServiceCenter] = useState(null);
  const [inputs, setInputs] = useState(defaultInputState);
  const [loading, setLoading] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [placeSearch, setPlaceSearch] = useState("");
  const [currentLocation, setCurrentLocation] = useState({
    lat: 0,
    lng: 0,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const { onCreateServiceCenter, onGetServiceCenter, onUpdateServiceCenter } =
    useContext(ServiceCentersContext);
  const navigate = useNavigate();
  setKey(GOOGLE_MAPS_API_KEY);

  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    if (selectedPlace) {
      let { lat, lng } = selectedPlace;
      if (lat && lng) {
        map.panTo(selectedPlace);
        fromLatLng(lat, lng).then(({ results }) => {
          const { formatted_address, address_components } = results[0];
          //   city and state
          const city = address_components.find((c) =>
            c.types.includes("locality")
          );
          const state = address_components.find((c) =>
            c.types.includes("administrative_area_level_1")
          );

          if (serviceCenter) {
            let lat = serviceCenter.location.latitude === selectedPlace.lat;
            let lng = serviceCenter.location.longitude === selectedPlace.lng;
            if (lat && lng) {
              return;
            }
          }

          setInputs((p) => ({
            ...p,
            address: {
              ...commonInputFieldProps,
              value: formatted_address,
            },
            state: {
              ...commonInputFieldProps,
              value: state ? state.long_name : "",
            },
            city: {
              ...commonInputFieldProps,
              value: city ? city.long_name : "",
            },
          }));
        });
      }
    }
  }, [selectedPlace, map]);

  useEffect(() => {
    if (mode) {
      let title =
        mode === "edit" ? "Edit Service Center" : "Add Service Center";
      document.title = title;
    }
    if (mode === "edit") {
      let editId = searchParams.get("id");
      if (!editId) {
        navigate("/dashboard/service-centers");
        return;
      }
      if (editId) {
        onGetServiceCenter(
          editId,
          (result) => {
            let serviceCenterData = result.serviceCenter;
            setServiceCenter(serviceCenterData);
            if (serviceCenterData) {
              setSelectedPlace({
                lat: Number(serviceCenterData.location.latitude),
                lng: Number(serviceCenterData.location.longitude),
              });
              let country = countries.find(
                (c) => c.phone === serviceCenterData.countryCode.toString()
              );
              setInputs((prevState) => ({
                ...prevState,
                name: {
                  ...commonInputFieldProps,
                  value: serviceCenterData.name,
                },
                description: {
                  ...commonInputFieldProps,
                  value: serviceCenterData.description,
                },
                countryCode: {
                  ...commonInputFieldProps,
                  value: country,
                },
                image: {
                  ...commonInputFieldProps,
                  value: serviceCenterData.image,
                },
                phone: {
                  ...commonInputFieldProps,
                  value: serviceCenterData.phone.toString(),
                },
                address: {
                  ...commonInputFieldProps,
                  value: serviceCenterData.address,
                },
                state: {
                  ...commonInputFieldProps,
                  value: serviceCenterData.location.state,
                },
                city: {
                  ...commonInputFieldProps,
                  value: serviceCenterData.location.city,
                },
                visible: {
                  ...commonInputFieldProps,
                  value: serviceCenterData.visible || false,
                },
                id: {
                  value: serviceCenterData._id,
                },
              }));
            } else {
              navigate("/dashboard/service-centers");
            }
          },
          () => {
            navigate("/dashboard/service-centers");
          },
          false,
          false
        );
      }
    }
  }, [mode]);

  const handleMapClick = useCallback((event) => {
    setSelectedPlace({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  }, []);

  const onLoadMap = (map) => {
    setMap(map);
  };

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setSelectedPlace(latLng);
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error in getting location", error);
        }
      );
    } else {
      console.error("Maps not supported");
    }
  };

  const onValueChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        error: false,
        errorMessage: "",
        value,
      },
    }));
  };

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    try {
      const base64Data = await convertFileToBase64(file);
      setInputs((p) => ({
        ...p,
        image: {
          ...p.image,
          value: base64Data,
        },
      }));
    } catch (error) {
      console.error("Error converting file to base64:", error);
    }
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    let hadErrors = false;
    const setErrorMessage = (name, message) => {
      setInputs((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          error: true,
          errorMessage: message,
        },
      }));
      hadErrors = true;
    };
    let {
      name,
      description,
      countryCode,
      phone,
      address,
      state,
      image,
      city,
      visible,
    } = inputs;
    name = name.value.trim();
    image = image.value;
    visible = visible.value;
    description = description.value?.trim();
    countryCode = countryCode.value;
    phone = phone.value.trim();
    address = address.value.trim();
    state = state.value.trim();
    city = city.value.trim();
    const phoneNumberRegex = /^[0-9]{10}$/;

    if (!name) {
      setErrorMessage("name", errors.nameRequired);
    }
    if (!countryCode) {
      setErrorMessage("countryCode", errors.countryCodeRequired);
    }
    if (!phone || !phoneNumberRegex.test(phone)) {
      setErrorMessage("phone", errors.phoneRequired);
    }
    if (!address) {
      setErrorMessage("address", errors.addressRequired);
    }
    if (!state) {
      setErrorMessage("state", errors.stateRequired);
    }
    if (!city) {
      setErrorMessage("city", errors.city);
    }
    if (!image) {
      showNotification({
        message: "Please upload image",
        status: "error",
      });
      hadErrors = true;
    }
    if (!selectedPlace) {
      showNotification({
        message: "Please select location from the MAP",
        status: "error",
      });
      hadErrors = true;
    }
    if (hadErrors) {
      return;
    }

    let data = {
      name: name,
      countryCode: Number(countryCode.phone),
      phone: Number(phone),
      address: address,
      location: {
        latitude: selectedPlace.lat,
        longitude: selectedPlace.lng,
        state: state,
        city: city,
      },
      image: image,
      description: description || null,
      visible: visible || false,
    };
    if (mode === "edit") {
      onUpdateServiceCenter(inputs.id.value, data, () => {
        navigate("/dashboard/service-centers");
      });
    } else {
      onCreateServiceCenter(data, () => {
        navigate("/dashboard/service-centers");
      });
    }
  };

  return (
    <section>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {mode === "create" ? "Add Service Center" : "Edit Service Center"}
          </Typography>
          <br />

          <Grid container spacing={3} className="mt-1">
            {/* for form */}
            <Grid item md={6}>
              <Box
                component="form"
                noValidate
                onSubmit={onSubmitForm.bind(this)}
                sx={{ mt: 2 }}
              >
                <Grid container columnSpacing={1}>
                  {/* name */}
                  <Grid item md={12}>
                    <TextField
                      error={inputs.name.error}
                      helperText={inputs.name.errorMessage}
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Name"
                      name="name"
                      value={inputs.name.value}
                      onChange={onValueChangeHandler}
                    />
                  </Grid>
                  {/* description */}
                  <Grid item md={12}>
                    <TextField
                      error={inputs.description.error}
                      helperText={inputs.description.errorMessage}
                      margin="normal"
                      fullWidth
                      id="description"
                      label="Description (optional)"
                      name="description"
                      value={inputs.description.value || ""}
                      onChange={onValueChangeHandler}
                    />
                  </Grid>
                  {/* phone */}
                  <Grid item md={4}>
                    <Autocomplete
                      id="countryCode"
                      className="mt-1"
                      fullWidth
                      options={countries}
                      value={inputs.countryCode.value || null}
                      autoHighlight
                      onChange={(e, newValue) => {
                        setInputs((prevState) => ({
                          ...prevState,
                          countryCode: {
                            ...prevState.countryCode,
                            error: false,
                            errorMessage: "",
                            value: newValue,
                          },
                        }));
                      }}
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                          key={option.label}
                        >
                          <img
                            loading="lazy"
                            width="20"
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            alt=""
                          />
                          {option.label} ({option.code}) +{option.phone}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={inputs.countryCode.error}
                          helperText={inputs.countryCode.errorMessage}
                          label="Country Code"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={8}>
                    <TextField
                      error={inputs.phone.error}
                      helperText={inputs.phone.errorMessage}
                      margin="normal"
                      fullWidth
                      id="phone"
                      label="Phone Number"
                      name="phone"
                      value={inputs.phone.value}
                      onChange={onValueChangeHandler}
                    />
                  </Grid>

                  {/* address */}
                  <Grid item md={12}>
                    <TextField
                      multiline
                      error={inputs.address.error}
                      helperText={inputs.address.errorMessage}
                      margin="normal"
                      fullWidth
                      id="address"
                      label="Address"
                      name="address"
                      value={inputs.address.value}
                      onChange={onValueChangeHandler}
                    />
                  </Grid>

                  {/* state */}
                  <Grid item md={12}>
                    <TextField
                      error={inputs.state.error}
                      helperText={inputs.state.errorMessage}
                      margin="normal"
                      fullWidth
                      id="state"
                      label="State"
                      name="state"
                      value={inputs.state.value}
                      onChange={onValueChangeHandler}
                    />
                  </Grid>

                  {/* city */}
                  <Grid item md={12}>
                    <TextField
                      error={inputs.city.error}
                      helperText={inputs.city.errorMessage}
                      margin="normal"
                      fullWidth
                      id="city"
                      label="City"
                      name="city"
                      value={inputs.city.value}
                      onChange={onValueChangeHandler}
                    />
                  </Grid>

                  {/* image upload */}
                  <Grid item md={4}>
                    <Button
                      variant="contained"
                      className="mt-1"
                      component="label"
                      endIcon={<AiFillFileImage />}
                    >
                      {inputs.image.value ? "Change Image" : "Upload Image"}
                      <input
                        type="file"
                        onChange={handleFileSelect}
                        hidden
                        accept="image/*"
                      />
                    </Button>
                  </Grid>
                  <Grid item md={8}>
                    {inputs.image?.value && (
                      <img
                        style={{
                          width: "100%",
                          height: "200px",
                          objectFit: "contain",
                        }}
                        src={inputs.image?.value}
                        alt="..."
                      />
                    )}
                  </Grid>

                  <Grid item md={12}>
                    <FormControlLabel
                      onChange={(e) =>
                        onValueChangeHandler({
                          target: {
                            name: "visible",
                            value: e.target.checked,
                          },
                        })
                      }
                      control={<Checkbox checked={inputs.visible.value} />}
                      label="Visible"
                    />
                  </Grid>

                  {/* submit button */}

                  <LoadingButton
                    type="submit"
                    fullWidth
                    loadingPosition="end"
                    endIcon={<AiFillShop />}
                    color="primary"
                    loading={loading}
                    loadingIndicator={"Authenticating..."}
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {!loading && mode === "edit"
                      ? "PROCEED & UPDATE"
                      : "PROCEED & ADD"}
                  </LoadingButton>
                </Grid>
              </Box>
            </Grid>
            {/* map select */}
            <Grid item md={6}>
              <PlacesAutocomplete
                value={placeSearch}
                onChange={(value) => setPlaceSearch(value)}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <TextField
                      {...getInputProps({
                        placeholder: "Enter a location to search",
                      })}
                      variant="standard"
                      //   className="mb-1"
                      fullWidth
                    />

                    <div
                      style={{
                        padding: "10px",
                      }}
                    >
                      {loading ? <div>Loading...</div> : null}

                      {suggestions.map((suggestion) => {
                        const style = {
                          backgroundColor: suggestion.active ? "#eee" : "#fff",
                          padding: "7px",
                          cursor: "pointer",
                        };

                        return (
                          <div
                            key={suggestion.placeId}
                            {...getSuggestionItemProps(suggestion, { style })}
                          >
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              {/* <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}> */}
              <GoogleMap
                mapContainerStyle={{
                  width: "100%",
                  height: "100%",
                }}
                onLoad={onLoadMap}
                center={currentLocation}
                zoom={17}
                onClick={handleMapClick}
              >
                {selectedPlace && <Marker position={selectedPlace} />}
              </GoogleMap>
              {/* </LoadScript> */}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </section>
  );
};

export default CreateEditServiceCenter;
