import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import _ from "lodash";
import CheckIcon from "@mui/icons-material/DoneAll";
import { getCurrencyLocaleString } from "../../../../utility/helper";
import classes from "./EditOrder.module.css";
import { OrdersContext } from "../../../../services/Orders/Orders.context";

const statuses = [
  {
    name: "Ordered",
    value: "ordered",
  },
  {
    name: "Shipped",
    value: "shipped",
  },
  {
    name: "Out For Delivery",
    value: "outForDelivery",
  },
  {
    name: "Delivered",
    value: "delivered",
  },
  {
    name: "Cancelled",
    value: "cancelled",
  },
];

const EditOrder = ({ mode }) => {
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { onGetOrder, onEditOrder } = useContext(OrdersContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (mode === "edit") {
      let editId = searchParams.get("id");
      if (!editId) {
        navigate("/dashboard/orders");
        return;
      }
      if (editId) {
        onGetOrder(
          editId,
          (result) => {
            let orderData = result.order;
            console.log(orderData);
            if (!orderData) {
              navigate("/dashboard/orders");
            }
            setOrder(orderData);
          },
          () => {
            navigate("/dashboard/orders");
          },
          false,
          false
        );
      }
    }
  }, [mode]);

  const onSubmit = (e) => {
    e.preventDefault();
    let hadErrors = false;
    let currentOrder = _.cloneDeep(order);

    for (let i = 0; i < order.items.length; i++) {
      let orderItem = order.items[i];
      if (!orderItem.status) {
        currentOrder.items[i].error = "status";
        currentOrder.items[i].errorMessage = "Please select the status";
        hadErrors = true;
        break;
      }
      if (!orderItem.notes) {
        currentOrder.items[i].error = "notes";
        currentOrder.items[i].errorMessage =
          "Please enter the notes or decription to user";
        hadErrors = true;
        break;
      }
    }

    if (hadErrors) {
      setOrder(currentOrder);
      return;
    }
    let items = [];

    currentOrder.items.forEach((item) => {
      items.push({
        _id: item._id,
        status: item.status,
        notes: item.notes,
      });
    });

    if (mode === "edit") {
      onEditOrder(
        order._id,
        {
          items: items,
        },
        (result) => {
          setOrder(result.order);
          window.scrollTo(0, 0);
          // navigate("/dashboard/orders");
        }
      );
    }
  };

  const getFormattedAddress = (item) => {
    let { houseNum, area, landmark, city, state, pincode } = item;
    let address = `${houseNum}, ${area} ${
      landmark ? "," + landmark : ""
    }, ${city}, ${state} - ${pincode}`;
    return address;
  };

  return (
    <section>
      {order && (
        <Box sx={{ mt: 3 }}>
          <Typography gutterBottom variant="h5" component="div">
            Order Id - {_.toUpper(order.order_id)}
          </Typography>

          <Box sx={{ minWidth: 275, mb: 3 }}>
            <Card variant="outlined">
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Deivery to : <b>{order.address.name}</b> (
                  {_.toUpper(order.address.type)})
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {getFormattedAddress(order.address)}
                </Typography>
                <Typography variant="body2">
                  <b>
                    {order.address.phone}{" "}
                    {order.address.alternativePhone
                      ? `,${order.address.alternativePhone}`
                      : ""}
                  </b>
                </Typography>
              </CardContent>
            </Card>
          </Box>
          <Grid container spacing={2}>
            {order.items.map((item, i) => {
              let {
                image,
                name,
                discount,
                amount,
                finalAmount,
                orderQuantity,
                error,
                errorMessage,
                codCharges,
                deliveryCharges,
                status,
                notes,
              } = item;
              discount = discount > 0 ? discount : "";
              let statusOption = statuses.find((s) => s.value === status);
              codCharges = codCharges > 0 ? codCharges : "";
              deliveryCharges = deliveryCharges > 0 ? deliveryCharges : "";

              return (
                <Grid item md={6} key={i}>
                  <Card>
                    <CardMedia
                      component="img"
                      alt="..."
                      height="250"
                      image={image}
                    />
                    <CardContent>
                      <p>{name}</p>
                      <div className={classes.priceQntyContainer}>
                        <div className={classes.prices}>
                          {discount && (
                            <p className={classes.discount}>
                              {getCurrencyLocaleString(discount)}
                            </p>
                          )}

                          {discount && (
                            <p className={classes.price}>
                              {getCurrencyLocaleString(amount)}
                            </p>
                          )}

                          <p className={classes.finalPrice}>
                            {getCurrencyLocaleString(finalAmount)}
                          </p>
                        </div>

                        <div className={classes.quantityContainer}>
                          <p>Quantity : {orderQuantity}</p>
                        </div>
                      </div>
                      {codCharges && (
                        <small>
                          Cod Charges : {getCurrencyLocaleString(codCharges)}
                        </small>
                      )}
                      <br />
                      {deliveryCharges && (
                        <small>
                          Delivery Charges :{" "}
                          {getCurrencyLocaleString(deliveryCharges)}
                        </small>
                      )}
                      <br />

                      <Autocomplete
                        disablePortal
                        id="status"
                        className="mt-1"
                        options={statuses}
                        fullWidth
                        value={statusOption || null}
                        onChange={(e, newValue) => {
                          let currentOrder = _.cloneDeep(order);
                          currentOrder.items[i].status = newValue.value;
                          currentOrder.items[i].error = null;
                          currentOrder.items[i].errorMessage = "";
                          setOrder(currentOrder);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={error === "status"}
                            helperText={error === "status" ? errorMessage : ""}
                            label="Status"
                          />
                        )}
                      />

                      <TextField
                        error={error === "notes"}
                        helperText={error === "notes" ? errorMessage : ""}
                        margin="normal"
                        placeholder="Enter Notes here"
                        required
                        fullWidth
                        id="notes"
                        label="Notes"
                        name="notes"
                        value={notes || ""}
                        onChange={(e) => {
                          let currentOrder = _.cloneDeep(order);
                          currentOrder.items[i].notes = e.target.value;
                          currentOrder.items[i].error = null;
                          currentOrder.items[i].errorMessage = "";

                          setOrder(currentOrder);
                        }}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
            <Button
              fullWidth
              onClick={onSubmit}
              variant="contained"
              sx={{
                mt: 5,
              }}
            >
              UPDATE STATUS
            </Button>
          </Grid>

          <TableContainer component={Paper} sx={{ mt: 4 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell>Price ({order.items.length} items) </TableCell>
                  <TableCell>
                    <strong>{getCurrencyLocaleString(order.subTotal)}</strong>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Saved </TableCell>
                  <TableCell>
                    <p className="text-muted">
                      -{getCurrencyLocaleString(order.discount)}
                    </p>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Delivery Charges </TableCell>
                  <TableCell>
                    {order.deliveryCharges > 0 ? (
                      getCurrencyLocaleString(order.deliveryCharges)
                    ) : (
                      <p className="text-success">FREE</p>
                    )}
                  </TableCell>
                </TableRow>
                {order.payment.paymentType === "cash" && (
                  <TableRow>
                    <TableCell>Cod Charges </TableCell>
                    <TableCell>
                      {order.codCharges > 0 ? (
                        getCurrencyLocaleString(order.codCharges)
                      ) : (
                        <p className="text-success">FREE</p>
                      )}
                    </TableCell>
                  </TableRow>
                )}

                <TableRow>
                  <TableCell>
                    <strong>Total Amount</strong>{" "}
                  </TableCell>
                  <TableCell>
                    <strong>{getCurrencyLocaleString(order.total)}</strong>
                  </TableCell>
                </TableRow>

                {order.payment.couponCode && (
                  <TableRow>
                    <TableCell>Coupon Code Applied</TableCell>
                    <TableCell>
                      <strong>
                        {_.toUpper(order.payment.couponCode.code)}
                      </strong>
                    </TableCell>
                  </TableRow>
                )}

                <TableRow>
                  <TableCell>
                    <strong>Payment Details</strong>{" "}
                  </TableCell>
                  <TableCell>
                    <Link
                      to={`/dashboard/payments?payment=${order.payment._id}`}
                      style={{
                        color: "blue",
                      }}
                    >
                      {order.payment.payment_id}
                    </Link>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <p className="text-success">
                      You saved{" "}
                      {getCurrencyLocaleString(
                        order.payment.couponCode
                          ? order.discount + order.payment.discount
                          : order.discount
                      )}{" "}
                      on this order
                    </p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <Button
            fullWidth
            variant="contained"
            startIcon={<CheckIcon />}
            style={{
              backgroundColor: "green",
            }}
          >
            PAID - {getCurrencyLocaleString(order.payment.amount / 100)}
          </Button>

          <br />
        </Box>
      )}
    </section>
  );
};

export default EditOrder;
