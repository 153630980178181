import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GiTicket } from "react-icons/gi";
import { MechanicContext } from "../../../../services/Mechanic/Mechanic.context";
import countries from "../../../../utility/countries.json";
import { ServiceCentersContext } from "../../../../services/ServiceCenters/ServiceCenters.context";
import { MdPeople } from "react-icons/md";

const errors = {
  nameRequired: "Mechanic name required",
  countryCodeRequired: "Country Code required",
  phoneRequired: "Invalid mobile number",
  serviceCenterRequired: "Please select service center",
};

const commonInputFieldProps = {
  value: "",
  focused: false,
  error: false,
  errorMessage: "",
};

const defaultInputState = {
  name: {
    ...commonInputFieldProps,
  },
  countryCode: {
    ...commonInputFieldProps,
    value: countries.find((c) => c.phone === "91"),
  },
  phone: {
    ...commonInputFieldProps,
  },
  serviceCenter: {
    ...commonInputFieldProps,
  },
  id: {
    ...commonInputFieldProps,
  },
};

const CreateEditMechanic = ({ mode }) => {
  const [mechanic, setMechanic] = useState(null);
  const [inputs, setInputs] = useState(defaultInputState);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [serviceCenters, setServiceCenters] = useState([]);
  const { onCreateMechanic, onGetMechanic, onUpdateMechanic } =
    useContext(MechanicContext);
  const { onGetServiceCenters } = useContext(ServiceCentersContext);

  const navigate = useNavigate();

  useEffect(() => {
    getServiceCenters();
    if (mode) {
      let title = mode === "edit" ? "Edit Mechanic" : "Add Mechanic";
      document.title = title;
    }
    if (mode === "edit") {
      let editId = searchParams.get("id");
      if (!editId) {
        navigate("/dashboard/mechanics");
        return;
      }
      if (editId) {
        onGetMechanic(
          editId,
          (result) => {
            let mechanicData = result.mechanic;
            setMechanic(mechanicData);
            if (mechanicData) {
              let { name, countryCode, phone, _id } = mechanicData;
              let country = countries.find(
                (c) => c.phone === countryCode.toString()
              );

              setInputs((prevState) => ({
                ...prevState,
                name: {
                  ...commonInputFieldProps,
                  value: name,
                },
                countryCode: {
                  ...commonInputFieldProps,
                  value: country,
                },
                phone: {
                  ...commonInputFieldProps,
                  value: phone.toString(),
                },
                serviceCenter: {
                  ...commonInputFieldProps,
                  value: mechanicData.serviceCenter,
                },
                id: {
                  value: _id,
                },
              }));
            } else {
              navigate("/dashboard/mechanics");
            }
          },
          () => {
            navigate("/dashboard/mechanics");
          },
          false,
          false
        );
      }
    }
  }, [mode]);

  const onValueChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        error: false,
        errorMessage: "",
        value,
      },
    }));
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    let hadErrors = false;
    const setErrorMessage = (name, message) => {
      setInputs((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          error: true,
          errorMessage: message,
        },
      }));
      hadErrors = true;
    };
    const returnValue = (value) => {
      return typeof value === "string" ? value?.trim() : value;
    };
    let { name, countryCode, phone, serviceCenter } = inputs;
    name = returnValue(name.value);
    countryCode = returnValue(countryCode.value);
    phone = returnValue(phone.value);
    serviceCenter = returnValue(serviceCenter.value);

    const phoneNumberRegex = /^[0-9]{10}$/;

    if (!name) {
      setErrorMessage("name", errors.nameRequired);
    }
    if (!countryCode) {
      setErrorMessage("countryCode", errors.countryCodeRequired);
    }
    if (!phone || !phoneNumberRegex.test(phone)) {
      setErrorMessage("phone", errors.phoneRequired);
    }

    if (!serviceCenter) {
      setErrorMessage("serviceCenter", errors.serviceCenterRequired);
    }

    if (hadErrors) {
      return;
    }
    let data = {
      name: name,
      countryCode: Number(countryCode.phone),
      phone: Number(phone),
      serviceCenter: serviceCenter._id,
    };

    if (mode === "edit") {
      onUpdateMechanic(inputs.id.value, data, () => {
        navigate("/dashboard/mechanics");
      });
    } else {
      onCreateMechanic(data, () => {
        navigate("/dashboard/mechanics");
      });
    }
  };

  const getServiceCenters = () => {
    onGetServiceCenters(
      (result) => {
        let serviceCenters = result.serviceCenters;
        setServiceCenters(serviceCenters);
      },
      false,
      false
    );
  };

  return (
    <section>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {mode === "create" ? "Add Mechanic" : "Edit Mechanic"}
          </Typography>
          <br />
          <Box
            component="form"
            noValidate
            onSubmit={onSubmitForm.bind(this)}
            sx={{ mt: 2 }}
          >
            <Grid container spacing={2}>
              {/* for form */}

              {/* name */}
              <Grid item md={6}>
                <TextField
                  error={inputs.name.error}
                  helperText={inputs.name.errorMessage}
                  margin="normal"
                  placeholder="Enter Mechanic Name "
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  value={inputs.name.value}
                  onChange={onValueChangeHandler}
                />
              </Grid>

              {/* service center */}
              <Grid item md={6}>
                <Autocomplete
                  disablePortal
                  id="serviceCenter"
                  className="mt-1"
                  options={serviceCenters}
                  fullWidth
                  value={inputs.serviceCenter.value || null}
                  onChange={(e, newValue) => {
                    onValueChangeHandler({
                      target: {
                        name: "serviceCenter",
                        value: newValue,
                      },
                    });
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={inputs.serviceCenter.error}
                      helperText={inputs.serviceCenter.errorMessage}
                      label="Service Center"
                    />
                  )}
                />
              </Grid>

              {/* phone */}
              <Grid item md={4}>
                <Autocomplete
                  id="countryCode"
                  className="mt-1"
                  fullWidth
                  options={countries}
                  value={inputs.countryCode.value || null}
                  autoHighlight
                  onChange={(e, newValue) => {
                    setInputs((prevState) => ({
                      ...prevState,
                      countryCode: {
                        ...prevState.countryCode,
                        error: false,
                        errorMessage: "",
                        value: newValue,
                      },
                    }));
                  }}
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                      key={option.label}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        alt=""
                      />
                      {option.label} ({option.code}) +{option.phone}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={inputs.countryCode.error}
                      helperText={inputs.countryCode.errorMessage}
                      label="Country Code"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={8}>
                <TextField
                  error={inputs.phone.error}
                  helperText={inputs.phone.errorMessage}
                  margin="normal"
                  fullWidth
                  id="phone"
                  label="Phone Number"
                  name="phone"
                  value={inputs.phone.value}
                  onChange={onValueChangeHandler}
                />
              </Grid>

              {/* submit button */}

              <LoadingButton
                type="submit"
                fullWidth
                loadingPosition="end"
                endIcon={<MdPeople />}
                color="primary"
                loading={loading}
                loadingIndicator={"Adding..."}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {!loading && mode === "edit"
                  ? "PROCEED & UPDATE"
                  : "PROCEED & ADD"}
              </LoadingButton>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </section>
  );
};

export default CreateEditMechanic;
