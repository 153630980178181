/* eslint-disable react-hooks/exhaustive-deps */
import { createContext } from "react";

import useHttp from "../../hooks/useHttp";

export const MechanicContext = createContext({
  onCreateMechanic: (data, callback, errorCallBack, loader, notify) => null,
  onUpdateMechanic: (
    mechanicId,
    data,
    callback,
    errorCallBack,
    loader,
    notify
  ) => null,
  onGetMechanics: (callback, loader, notify) => null,
  onGetMechanic: (mechanicId, callback, errorCallBack, loader, notify) => null,
  onDeleteMechanic: (mechanicId, callback, errorCallBack, loader, notify) =>
    null,
});

export const MechanicContextProvider = ({ children }) => {
  const { sendRequest } = useHttp();

  const baseUrl = "/admin/";

  const onCreateMechanic = async (
    data,
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + `mechanic/`,
        type: "POST",
        data: data,
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  const onUpdateMechanic = async (
    mechanicId,
    data,
    callback,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + `mechanic/` + mechanicId,
        type: "PUT",
        data: data,
      },
      {
        successCallback: async (result) => {
          callback(result);
        },
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  const onGetMechanics = async (
    callback = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + "mechanic",
      },
      {
        successCallback: callback,
      },
      loader,
      notify
    );
  };

  const onGetMechanic = async (
    mechanicId,
    callback = () => null,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + "mechanic/" + mechanicId,
      },
      {
        successCallback: callback,
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  const onDeleteMechanic = async (
    mechanicId,
    callback = () => null,
    errorCallBack = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + "mechanic/" + mechanicId,
        type: "DELETE",
      },
      {
        successCallback: callback,
        errorCallback: errorCallBack,
      },
      loader,
      notify
    );
  };

  return (
    <MechanicContext.Provider
      value={{
        onCreateMechanic,
        onUpdateMechanic,
        onGetMechanic,
        onGetMechanics,
        onDeleteMechanic,
      }}
    >
      {children}
    </MechanicContext.Provider>
  );
};
