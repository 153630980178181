/* eslint-disable react-hooks/exhaustive-deps */
import { createContext } from "react";

import useHttp from "../../hooks/useHttp";

export const DashboardContext = createContext({
  onGetDashboardData: (callback, loader, notify) => null,
});

export const DashboardContextProvider = ({ children }) => {
  const { sendRequest } = useHttp();

  const baseUrl = "/admin/";

  const onGetDashboardData = async (
    callback = () => null,
    loader = true,
    notify = true
  ) => {
    sendRequest(
      {
        url: baseUrl + "dashboard",
      },
      {
        successCallback: callback,
      },
      loader,
      notify
    );
  };

  return (
    <DashboardContext.Provider
      value={{
        onGetDashboardData,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
